import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import paths from "../../../constants/paths";
import { useOnboarding } from "../../../contexts/onboarding";
import { useCommon } from "../../../contexts/common";
import { useState } from "react";
import { LOADING } from "../../../constants/common";
import { useParams } from "react-router-dom";
import { validateLinkToken } from "../../../services/auth.services";
import Avatar from "@mui/material/Avatar";
import { ReactComponent as UserIcon } from "../../../assets/Icons/userinvited.svg";
import {
  validateEmail,
  validatePassword,
  validateName,
} from "../../../helper/Validations";
import "./style.css";
import {
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const RegisterForm = () => {
  const {
    next,
    onboardingState,
    updateOnboardingState,
    updatesetInvitatedUser,
  } = useOnboarding();
  const { checkIfEmailExists, emailExists, handleState, emailExistsStatus } =
    useCommon();
  const [isInvalid, setIsInvalid] = useState(false);
  const [invitatedUser, setInvitatedUser] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let { token } = useParams();

  useEffect(() => {
    if (token) {
      validateLinkToken(token)
        .then((res) => {
          updatesetInvitatedUser({ ...res?.data?.data?.user, token });
          setInvitatedUser(res?.data?.data?.user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const handleInput = (ev) => {
    if (ev.target.name === "email") {
      handleState({ emailExists: false });
    }
    if (isValid()) {
      setIsInvalid(false);
    }
    updateOnboardingState({ [ev.target.name]: ev.target.value });
  }

  const isValid = () => {
    return (
      onboardingState.first_name
      && onboardingState.last_name 
      && onboardingState.email
      && onboardingState.password
      && validateName(onboardingState.first_name) 
      && validateName(onboardingState.last_name) 
      && validateEmail(onboardingState.email)
      && validatePassword(onboardingState.password) 
      && emailExistsStatus !== LOADING
    );
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (!isValid()) {
      setIsInvalid(true);
      return;
    }
    const response = await checkIfEmailExists(onboardingState.email);
    if (isValid() && !Boolean(response.data.code)) {
      next();
    } else setIsInvalid(true);
  }

  return (
    <Container maxWidth="sm">
      {invitatedUser && (
        <>
          <Box
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              p: 4,
              backgroundColor: "#E7F4FD",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              mb: -4,
            }}
          >
            <Typography variant="body1" align="center" color={"#19CB6F"}>
              1 Month Free
            </Typography>
            <Typography
              variant="body1"
              align="center"
              mt={2}
              sx={{
                fontSize: "30px",
                lineHeight: "38px",
                fontWeight: "normal",
              }}
            >
              Join Word of Mouth
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                align="center"
                mt={2}
                sx={{
                  fontSize: "15px",
                }}
              >
                Invited by:
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {invitatedUser.main_image ? (
                  <Avatar
                    alt="user Image"
                    src={invitatedUser.main_image}
                    align="center"
                    style={avatarStyle}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: "#FFF",
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <UserIcon />
                  </div>
                )}
              </div>
            </Box>
            <Typography
              variant="body1"
              align="center"
              mt={2}
              sx={{
                fontSize: "15px",
                fontWeight: 700,
              }}
            >
              {invitatedUser.first_name} {invitatedUser.last_name}
            </Typography>
          </Box>
        </>
      )}
      <div className="hideShadow">
        <Paper sx={{ minHeight: "75vh" }} maxwidth="xs">
          {!invitatedUser ? (
            <>
              <div className="createAccount">
                <Typography variant="h3" mb={1}>
                  Create an account
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "39px" }}>
                  This information needs to be accurate
                </Typography>
              </div>
            </>
          ) : (
            <Typography
              variant="body1"
              align="center"
              mt={2}
              sx={{
                fontSize: "15px",
              }}
            ></Typography>
          )}
          <Box component="form">
            <div className="marginForm">
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    required
                    onChange={handleInput}
                    value={onboardingState.first_name || ""}
                    name="first_name"
                    error={
                      isInvalid && !validateName(onboardingState.first_name)
                    }
                    helperText={
                      isInvalid 
                      && !validateName(onboardingState.first_name) 
                      && "Please add first name"
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    onChange={handleInput}
                    required
                    value={onboardingState.last_name || ""}
                    name="last_name"
                    error={isInvalid && !validateName(onboardingState.last_name)}
                    helperText={ 
                      isInvalid 
                      && !validateName(onboardingState.last_name) 
                      && "Please add last name"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={handleInput}
                    value={onboardingState.email || ""}
                    name="email"
                    required
                    error={
                      isInvalid &&
                      (Boolean(emailExists) ||
                        !validateEmail(onboardingState.email))
                    }
                    helperText={
                      isInvalid &&
                      (Boolean(emailExists)
                        ? "This email already exist"
                        : !validateEmail(onboardingState.email)
                          ? "Please add valid email"
                          : "")
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="outlined-adornment-password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    label="Password"
                    required
                    onChange={handleInput}
                    value={onboardingState.password || ""}
                    name="password"
                    error={
                      isInvalid && !validatePassword(onboardingState.password)
                    }
                    helperText={
                      isInvalid
                      && !validatePassword(onboardingState.password) 
                      && (
                        <ul>
                          <li>A minimum of 12 characters</li>
                          <li>At least one uppercase letter</li>
                          <li>At least one lowercase letter</li>
                          <li>At least one number</li>
                          <li>
                            At least one special character from this list:
                            #.-?!@$%^&*
                          </li>
                        </ul>
                      )
                    }
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box mb={3} textAlign="center">
            <Link href={paths.login} fontWeight={600}>
              I have an account
            </Link>
          </Box>
          <div className="displayNone">
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                maxWidth: 300,
                mx: "auto",
                textAlign: "center",
              }}
            >
              By signing up, you agree to the{" "}
              <a
                sx={{ color: "#0C8CE9 !important" }}
                href={"https://wordofmouththerapists.com/terms"}
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                sx={{ color: "#0C8CE9 !important" }}
                href={"https://wordofmouththerapists.com/privacy"}
              >
                Privacy Policy.
              </a>
            </Typography>
          </div>
        </Paper>
      </div>
    </Container>
  );
};

const avatarStyle = {
  width: "60px",
  height: "60px",
  marginTop: "8px",
};

export default RegisterForm;
