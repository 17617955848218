import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";

import Faq from "./components/Faq";
import SpecialSubsciptionPlans from "./components/SpecialSubsciptionPlans";
import Guides from "./components/Guides";
import { useOnboarding } from "../../../contexts/onboarding";
import CheckoutModal from "../CheckoutForm";

const Subsciption = () => {
  const { registerStatus } = useOnboarding();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          padding: 0,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "38px",
            fontWeight: 500,
            lineHeight: "46px",
            width: "425px",
            textAlign: "center"
          }}
        >
          Therapy Word of Mouth Member Benefits
        </Typography>


      </Box>
      <Box
        style={{
          display: "flex",
          padding: 0,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "center",
            color: "#F57953"
          }}
        >
          SPECIAL OFFER
        </Typography>
      </Box>
      <div className="payment-guid-container">
        <SpecialSubsciptionPlans handleCheckout={handleOpen} />
        <Guides />
      </div>
      <Box
        sx={{
          backgroundColor: "#FAFBFC",
          py: 6,
        }}
      >
        <Box
          sx={{
            maxWidth: 1031,
            mx: "auto",
          }}
        >
          <Grid container spacing={7}>
            <Grid item xs={2.8}>
              <Typography variant="h3" mb={2.5}>
                Frequently asked questions
              </Typography>
              <Typography variant="body2">
                Haven't found what you're looking for? Try{" "}
                <Link href="#" variant="body2">
                  contact us
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={9.2}>
              <Faq />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box minHeight={86}>
        <Box
          component="footer"
          sx={{
            boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.04)",
            px: 5,
            py: 2.5,
            display: "flex",
            justifyContent: "flex-end",
            position: "fixed",
            width: "100%",
            bottom: 0,
            backgroundColor: "background.paper",
          }}
        >
          <LoadingButton
            variant="contained"
            sx={{
              minWidth: 212,
            }}
            size="large"
            onClick={handleOpen}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
      <CheckoutModal open={open} onClose={handleClose} />
    </>
  );
};

export default Subsciption;
