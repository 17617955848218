import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import inviteTherapistImage from "../../assets/imgs/invite-therapist.png";
import Typography from "@mui/material/Typography";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { ReactComponent as CopyIcon } from "../../assets/Icons/copy.svg";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import copy from "clipboard-copy";
import { getInvitationLink } from "../../services/home.services";
import { baseUrl } from "../../constants/common"
import { TrainRounded } from "@mui/icons-material";

function InviteTherapistModal({ open, onClose }) {
  const [link, setLink] = useState("");
  const [originalLink, setOriginalLink] = useState("");
  const [copied, setCopied] = useState(false); 
  // const regex = /onboarding\/(.{4})/;
  const regex = /onboarding\/([a-f0-9]{64})$/;
 
  function handleClose() {
    onClose();
  }

  useEffect(() => {
    getInvitationLink()
      .then((res) => {
        const match = regex.exec(res.data.data.link);
        setOriginalLink(res.data.data.link);
        setLink(`${baseUrl}/invite/${match[1]}`)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function handleOnCopyButtonClick(ev) {
    try {
      await copy(originalLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={containerstyle}>
        <Box sx={closeButtonstyle}>
          <IconButton
            onClick={handleClose}
            sx={{ p: 0.25, color: "border.dark" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component="form"
          sx={{
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#E7F4FD",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "162px",
              width: "200px",
              justifyContent: "center",
            }}
          >
            <img src={inviteTherapistImage} alt="invite Therapist" />
          </Box>
          <Typography
            variant="body1"
            align="center"
            mt={2}
            sx={{ fontSize: "30px", lineHeight: "38px", fontWeight: "medium" }}
          >
            Gift a friend 1 month free
            <br /> Get a <span style={{ color: "#19CB6F"}}>free</span> month
          </Typography>
        </Box>
        <Box m={4} mt={2}>
          <Typography
            variant="body2"
            sx={{ color: "#303336", fontSize: "15px" }}
          >
            Copy and send your personalized link below.
          </Typography>
          <Grid container mt={1}>
            <TextField
              variant="outlined"
              disabled={true}
              fullWidth
              // InputLabelProps={{ shrink: true }}
              value={originalLink}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleOnCopyButtonClick}>
                      <CopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={handleOnCopyButtonClick}
            >
            {copied ? "Copied" : "Copy link"}
            </Button>
          </Grid>
          
        </Box>
      </Paper>
    </Modal>
  );
}

const containerstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 0,
  overflow: "hidden",
  width: 526,
};

const closeButtonstyle = {
  position: "absolute",
  top: "5%",
  left: "95%",
  transform: "translate(-50%, -50%)",
};

export default InviteTherapistModal;
