import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { ReactComponent as DollarSignIcon } from "../../../assets/Icons/dollar-sign.svg";
import { ReactComponent as FeeIcon } from "../../../assets/Icons/fee.svg";

import { ReactComponent as HouseIcon } from "../../../assets/Icons/house.svg";
import { ReactComponent as GraduationCapIcon } from "../../../assets/Icons/graduation-cap.svg";
import { ReactComponent as CorthouseIcon } from "../../../assets/Icons/corthouse.svg";
import { ReactComponent as LicenseIcon } from "../../../assets/Icons/license-icon.svg";
import { ReactComponent as MembershipIcon } from "../../../assets/Icons/membership.svg";
import { ReactComponent as LicenseVarificationIcon } from "../../../assets/Icons/license-varification.svg";
import { useUserProfile } from "../../../contexts/UserProfile";
import {
  full_and_need_to_refer_out,
  googleApiKey,
  treatment_program,
  inperson_session,
  has_video_provided,
  psypact_provider,
} from "../../../constants/common";
import AgeServed from "./components/AgeServed";
import ClientTypes from "./components/ClientTypes";
import CurrentlyServed from "./components/CurrentlyServed";
import { useSearchParams } from "react-router-dom";
import CenterOverview from "./components/CenterOverview";
import PaymentInformation from "./components/PaymentInformation";
import { Wrapper } from "@googlemaps/react-wrapper";
import MapComponent from "../../../components/MapComponent";
import Faq from "./components/Faq";
import Marker from "../../../components/MapComponent/Marker";

const ProfileContent = () => {
  const { data } = useUserProfile();
  const licence = data.credential_types?.find(
    (item) => item.type === "licence"
  );
  const [searchParams] = useSearchParams();
  const account_type = searchParams.get("account_type");
  const hasClientServed = data?.client_types?.some(item => item?.currently_served)

  return (
    <>
      {data?.info?.about_me && (
        <Paper
          sx={{
            p: 3,
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight={600} mb={3}>
            About
          </Typography>
          <Typography variant="body1">{data?.info?.about_me}</Typography>
        </Paper>
      )}
      {(data.degrees?.length > 0 || data.credential_types?.length > 0) && (
        <Paper
          sx={{
            px: 3,
            pt: 3,
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight={600} mb={3}>
            Qualifications
          </Typography>
          {Boolean(licence) && (
            <Box
              sx={{
                backgroundColor: "#F3F9FD",
                borderRadius: 3,
                p: 1.5,
                display: "inline-flex",
                alignItems: "center",
                gap: 1.5,
                mb: 3,
              }}
            >
              <LicenseVarificationIcon />
              <Box>
                <Typography variant="body2" fontWeight="600" mb={0.5}>
                  Verified by Therapy Word of Mouth
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Licensed by {licence.data.title} / {licence.data.number}
                </Typography>
              </Box>
            </Box>
          )}

          {data.degrees?.length > 0 && (
            <>
              {Boolean(data.degrees[0].practice_year) && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  <CorthouseIcon />
                  <Typography variant="body1">
                    In Practice for{" "}
                    {moment().diff(
                      moment(data.degrees[0].practice_year),
                      "years"
                    )}{" "}
                    Years
                  </Typography>
                </Box>
              )}
              {/* <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  mb: 3,
                }}
              >
                <GraduationCapIcon />
                <Box>
                  <Typography variant="body1">
                    Education: {data.degrees[0].degree?.name}
                  </Typography>
                  <Typography variant="body2" color="border.dark">
                    {data.degrees[0].college_name} /{" "}
                    {data.degrees[0].degree_year}
                  </Typography>
                </Box>
              </Box> */}
            </>
          )}
          {data.degrees?.length > 0 && (
            <Paper
              sx={{
                px: 3,
                pt: 3,
                pb: 1.5,
                mb: 2,
              }}
            >
              <Typography variant="h5" fontWeight={600} mb={3}>
                Education
              </Typography>
              <Box
                component="ul"
                sx={{
                  columns: 2,
                  pl: 2,
                  m: 0,
                }}
              >
                {data.degrees?.map((item) => (
                  <Typography variant="body1" component="li" key={item.id} mb={1.5}>
                    {item?.degree?.name}  <Typography variant="body2" color="border.dark">
                      {item.college_name} /{" "}
                      {item.degree_year}
                    </Typography>
                  </Typography>
                ))}
              </Box>
            </Paper>
          )}
          {data.credential_types?.length > 0 && (
            <>
              {data.credential_types?.reduce((acc, item) => {
                if (item.type === "certificate") {
                  return [
                    ...acc,
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        mb: 3,
                      }}
                      key={item.id}
                    >
                      <GraduationCapIcon />
                      <Box>
                        <Typography variant="body1">
                          Certificate: {item.data.title}
                        </Typography>
                        <Typography variant="body2" color="border.dark">
                          {item?.state?.name}, {item?.data?.number} /{" "}
                          {item?.data?.year}
                        </Typography>
                      </Box>
                    </Box>,
                  ];
                } else {
                  return acc;
                }
              }, [])}

              {data.credential_types?.reduce((acc, item) => {
                if (item.type === "licence") {
                  return [
                    ...acc,
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        mb: 3,
                      }}
                      key={item.id}
                    >
                      <LicenseIcon />
                      <Box>
                        <Typography variant="body1">
                          License: {item.data.title}
                        </Typography>
                        <Typography variant="body2" color="border.dark">
                          {item?.state?.name}, {item?.data?.number} /{" "}
                          {item?.data?.year}
                        </Typography>
                      </Box>
                    </Box>,
                  ];
                } else {
                  return acc;
                }
              }, [])}

              {data.credential_types?.reduce((acc, item) => {
                if (item.type === "membership") {
                  return [
                    ...acc,
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        mb: 3,
                      }}
                      key={item.id}
                    >
                      <MembershipIcon />
                      <Box>
                        <Typography variant="body1">
                          Membership: {item.data.title}
                        </Typography>
                        <Typography variant="body2" color="border.dark">
                          {item?.state?.name}, {item?.data?.number} /{" "}
                          {item?.data?.year}
                        </Typography>
                      </Box>
                    </Box>,
                  ];
                } else {
                  return acc;
                }
              }, [])}
            </>
          )}
        </Paper>
      )}

      {account_type === treatment_program ? (
        <CenterOverview />
      ) : (
        <>
          {(data.client_types?.length > 0 || data.info?.age_served) &&
            data?.info?.current_status !== full_and_need_to_refer_out && (
              <Paper
                sx={{
                  p: 3,
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={600} mb={3}>
                  Client types:
                </Typography>
                <Grid container spacing={1} alignItems="stretch">
                  {data.info?.age_served && (
                    <Grid item xs={12} sm={6}>
                      <AgeServed />
                    </Grid>
                  )}
                  {data.client_types?.length > 0 && (
                    <Grid item xs={12} sm={6}>
                      <ClientTypes />
                    </Grid>
                  )}
                  {data.client_types?.length > 0 && hasClientServed && (
                    <Grid item xs={12} sm={6}>
                      <CurrentlyServed />
                    </Grid>
                  )}
                </Grid>
                <div style={{ display: "block", marginTop: "16px" }}>
                  {data.info?.inperson_session ? (
                    <Box sx={{ marginTop: "8px" }}>
                      <Box
                        component="ul"
                        sx={{
                          columns: 2,
                          pl: 2,
                          m: 0,
                        }}
                      >
                        <Typography variant="body1" component="li" mb={1.5}>
                          {inperson_session.label}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {data.info?.has_video_provided ? (
                    <Box sx={{ marginTop: "8px" }}>
                      <Box
                        component="ul"
                        sx={{
                          columns: 2,
                          pl: 2,
                          m: 0,
                        }}
                      >
                        <Typography variant="body1" component="li" mb={1.5}>
                          {has_video_provided.label}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {data.info?.psypact_provider ? (
                    <Box sx={{ marginTop: "8px" }}>
                      <Box
                        component="ul"
                        sx={{
                          columns: 2,
                          pl: 2,
                          m: 0,
                        }}
                      >
                        <Typography variant="body1" component="li" mb={1.5}>
                          {psypact_provider.label}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </div>
              </Paper>
            )}
        </>
      )}

      {account_type === treatment_program && (
        <>
          <PaymentInformation />
          {data?.info?.address && (
            <Paper
              sx={{
                p: 3,
                mb: 2,
              }}
            >
              <Wrapper apiKey={googleApiKey}>
                <MapComponent
                  center={{
                    // lat: 40.417286,
                    // lng: -82.907120,
                    lat: parseFloat(data?.info?.address?.latitude),
                    lng: parseFloat(data?.info?.address?.longitude),
                  }}
                  zoom={10}
                >
                  <Marker
                    position={{
                      // lat: 40.417286,
                      // lng: -82.907120,
                      lat: parseFloat(data?.info?.address?.latitude),
                      lng: parseFloat(data?.info?.address?.longitude),
                    }}
                  />
                </MapComponent>
              </Wrapper>
            </Paper>
          )}
          <Faq />
        </>
      )}
      {data.specialties?.length > 0 && (
        <Paper
          sx={{
            px: 3,
            pt: 3,
            pb: 1.5,
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight={600} mb={3}>
            Specialties
          </Typography>
          <Box
            component="ul"
            sx={{
              columns: 2,
              pl: 2,
              m: 0,
            }}
          >
            {data.specialties?.map((item) => (
              <Typography variant="body1" component="li" key={item.id} mb={1.5}>
                {item?.specialty?.name}
              </Typography>
            ))}
          </Box>
        </Paper>
      )}

      {data.treatment_modalities?.length > 0 && (
        <Paper
          sx={{
            px: 3,
            pt: 3,
            pb: 1.5,
            mb: 2,
          }}
        >
          {Boolean(
            data.treatment_modalities?.find(
              (item) => item?.type === "prescribers"
            )
          ) &&
            Boolean(data?.info?.has_medication_prescriber) && (
              <>
                <Typography variant="h5" fontWeight={600} mb={3}>
                  Medication Management
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    columns: 2,
                    pl: 2,
                    m: 0,
                  }}
                >
                  {data.treatment_modalities?.reduce((acc, item) => {
                    if (item?.type === "prescribers") {
                      return [
                        ...acc,
                        <Typography
                          variant="body1"
                          component="li"
                          key={item.id}
                          mb={1.5}
                        >
                          {item?.name}
                        </Typography>,
                      ];
                    }
                    return acc;
                  }, [])}
                </Box>
                <Divider sx={{ my: 3 }} />
              </>
            )}

          {Boolean(
            data.treatment_modalities?.find(
              (item) => item?.type === "therapist"
            )
          ) && (
              <>
                <Typography variant="h5" fontWeight={600} mb={3}>
                  Psychotherapy
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    columns: 2,
                    pl: 2,
                    m: 0,
                  }}
                >
                  {data.treatment_modalities?.reduce((acc, item) => {
                    if (item?.type === "therapist") {
                      return [
                        ...acc,
                        <Typography
                          variant="body1"
                          component="li"
                          key={item.id}
                          mb={1.5}
                        >
                          {item?.name}
                        </Typography>,
                      ];
                    }
                    return acc;
                  }, [])}
                </Box>
              </>
            )}
        </Paper>
      )}

      {(data.fees?.length > 0 || data.insurance?.length > 0 || data.private_pay_only) && (
        <Paper
          sx={{
            px: 3,
            pt: 3,
            mb: 2,
            display: "flex",
            gap: 1,
          }}
        >
          {data.fees?.length > 0 && (
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h5" fontWeight={600} mb={3}>
                Finances
              </Typography>
              {data.fees?.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    mb: 3,
                  }}
                  key={item.id}
                >
                  <FeeIcon />
                  <Box>
                    <Typography variant="body1" lineHeight="24px">
                      {item.feedata?.name} Fee:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      display="flex"
                      alignItems="center"
                    >
                      {item.fee?.includes(",") ? (
                        item.fee?.split(",").map((item, index) => (
                          <Box
                            component="span"
                            mr={1}
                            display="flex"
                            alignItems="center"
                          >
                            {index === 0 ? "From:" : "To:"}
                            <DollarSignIcon /> {item}
                          </Box>
                        ))
                      ) : (
                        <>
                          <DollarSignIcon /> {item.fee}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {data.insurance?.length > 0 && (
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h5" fontWeight={600} mb={3}>
                Accepted Insurance Plans:
              </Typography>
              {data.insurance?.map((item) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    mb: 3,
                  }}
                  key={item.id}
                >
                  <Box
                    sx={{
                      width: 28,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <HouseIcon />
                  </Box>
                  <Typography variant="body1" lineHeight="24px">
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          {data?.private_pay_only ? (
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h5" fontWeight={600} mb={3}>
                Private Pay
              </Typography>
            </Box>
          ): null}
        </Paper>
      )}

      {data.languages?.length > 0 && (
        <Paper
          sx={{
            px: 3,
            pt: 3,
            pb: 1.5,
            mb: 2,
          }}
        >
          <Typography variant="h5" fontWeight={600} mb={3}>
            Languages
          </Typography>
          <Box
            component="ul"
            sx={{
              columns: 2,
              pl: 2,
              m: 0,
            }}
          >
            {data.languages?.map((item) => (
              <Typography variant="body1" component="li" key={item.id} mb={1.5}>
                {item?.name}
              </Typography>
            ))}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default ProfileContent;
