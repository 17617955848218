import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import ClientsServedDrawer from '../components/ClientsServedDrawer';
import { getClientTypes, getUserClientTypes, updateAgesServed, updateClientTypes } from '../services/clientTypes.services';


const ClientServedContext = createContext({});

export const ClientServedContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [stateData, setStateData] = useState({
        clientTypes: [],
        fetchClientTypesStatus: '',
        updateClientTypesStatus: '',
        userClientTypes: [],
        fetchUserClientTypesStatus: '',
        editAgesServedStatus: '',
    });

    const [openClientsServed, setClientsServedOpen] = useState(false);

    function handleOpenClientsServedDrawer() {
      setClientsServedOpen(true)
    }

    function handleCloseClientsServedDrawer() {
      setClientsServedOpen(false)
    }


    function handleState(obj) {
        setStateData((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
    

    async function fetchClientTypes() {
        handleState({ fetchClientTypesStatus: LOADING })
      try {
        const response = await getClientTypes();
        handleState({
            clientTypes: response.data.data,
            fetchClientTypesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchClientTypesStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editClientTypes(data) {
        handleState({ updateClientTypesStatus: LOADING })
      try {
        await updateClientTypes(data);
        handleState({
            updateClientTypesStatus: SUCCESS
        })
        sendAlert('Updated client types successfully', 'success')
      } catch (error) {
        handleState({ updateClientTypesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function fetchUserClientTypes() {
        handleState({ fetchUserClientTypesStatus: LOADING })
      try {
        const response = await getUserClientTypes();
        handleState({
            userClientTypes: response.data.data,
            fetchUserClientTypesStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchUserClientTypesStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editAgesServed(data, userType) {
        handleState({ editAgesServedStatus: LOADING })
      try {
        const response = await updateAgesServed(data, userType);

        handleState({
            editAgesServedStatus: SUCCESS
        })
        sendAlert('Updated Ages Served successfully', 'success')

        return response;
      } catch (error) {
        handleState({ editAgesServedStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }
    
    function updateClientTypesState(field, value) {
        handleState({ [field]: value })
    }
    

    const state = {
        ...stateData,
        fetchClientTypes,
        editClientTypes,
        fetchUserClientTypes,
        handleOpenClientsServedDrawer,
        editAgesServed,
        updateClientTypesState,
    };

    return (
        <ClientServedContext.Provider value={state}>
          {children}

          <ClientsServedDrawer
            open={openClientsServed}
            onClose={handleCloseClientsServedDrawer}
          />
        </ClientServedContext.Provider>
    );
};

export const useClientServed = () => useContext(ClientServedContext);