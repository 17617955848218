import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTeams } from '../../../contexts/Teams'
import { LOADING, SUCCESS } from '../../../constants/common'
import { useCommon } from '../../../contexts/common'
import { validateEmail, validateName, validatePassword } from '../../../helper/Validations'

const Register = () => {
  const { organizationData, next, registerTeamMember, registerMemberStatus, updateTeamsState } = useTeams();
  const { checkIfEmailExists, emailExists } = useCommon();
  const [isInvalid, setIsInvalid] = useState(false);
  const initialState = {
    first_name: '',
    last_name: '',
    password: '',
    email: '',
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (registerMemberStatus === SUCCESS) {
      next();
      updateTeamsState('registerMemberStatus', '');
      setState(initialState);
    }
  }, [registerMemberStatus])
  
  useEffect(() => {
    if (organizationData?.params?.email) {
      setState((prevState) => ({
        ...prevState,
        email: organizationData?.params?.email
      }))
    }
  }, [organizationData?.params?.email])

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return validateName(state.first_name)
    && validateName(state.last_name)
    && validatePassword(state.password)
    && validateEmail(state.email)
  }

  async function handleSubmit(ev) {
    ev.preventDefault();
    const response = await checkIfEmailExists(state.email);
    if (isValid() && !Boolean(response.data.code)) {
      const payload = {
        first_name: state.first_name,
        last_name: state.last_name,
        password: state.password,
        team_id: organizationData?.params?.team_id,
        role: organizationData?.params?.role,
        url_id: organizationData?.id,
        invite_as_mental_health: organizationData?.params?.invite_as_mental_health,
      }
      if (organizationData?.params?.email) {
        payload.email = organizationData?.params?.email
      }  else {
        payload.email = state.email
      }
      registerTeamMember(payload);
    } else
      setIsInvalid(true)
  }
  
  return (
    <Container maxWidth='sm'>
      <Paper
        sx={{
          textAlign: 'center',
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            backgroundColor: 'primary.light',
            pt: 4,
            px: 4,
            pb: 3,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant='h1' maxWidth={386} mx='auto' mb={1}>Join {organizationData?.params?.program_name} on Word of Mouth</Typography>
          <Typography variant='body1' mb={3}>Save time. Be in the know. <br />Referrals made easy</Typography>
          <Avatar
            sx={{
              width: 60,
              height: 60,
              mx: 'auto',
            }}
            src={organizationData?.params?.program_logo}
          />
        </Box>
        <Box
              sx={{
                pt: 3,
                pb: 4,
                px: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
              }}
              component='form'
              onSubmit={handleSubmit}
            >
              {organizationData?.params?.email && <Typography variant='body1'>You're accepting an invitation sent to {organizationData?.params?.email}.</Typography>}
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='First Name'
                    onChange={handleInput}
                    error={isInvalid && !validateName(state.first_name)}
                    value={state.first_name}
                    name='first_name'
                    helperText={
                      isInvalid
                      && !validateName(state.first_name)
                      && "Please enter valid first name"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Last Name'
                    onChange={handleInput}
                    error={isInvalid && !validateName(state.last_name)}
                    value={state.last_name}
                    name='last_name'
                    helperText={
                      isInvalid
                      && !validateName(state.last_name)
                      && "Please enter valid last name"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Email'
                    type='email'
                    onChange={handleInput}
                    error={Boolean(emailExists) || (isInvalid && (!state.email || !validateEmail(state.email)))}
                    value={state.email}
                    name='email'
                    disabled={Boolean(organizationData?.params?.email)}
                    helperText={
                      Boolean(emailExists) && 'Email already exists please choose another one'
                      || isInvalid && !validateEmail(state.last_name) && "Please enter valid last name"

                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Password'
                    type='password'
                    onChange={handleInput}
                    error={isInvalid && !validatePassword(state.password)}
                    value={state.password}
                    name='password'
                    helperText={
                      isInvalid
                      && !validatePassword(state.password)
                      && "Please enter valid password"
                    }
                  />
                </Grid>
              </Grid>
              <LoadingButton
                  size='large'
                  fullWidth
                  variant='contained'
                  type='submit'
                  loading={registerMemberStatus === LOADING}
              >
                  Continue
              </LoadingButton>
              <Typography
                  variant='body2'
                  sx={{
                      color: 'text.secondary',
                      maxWidth: 300,
                      mx: 'auto',
                      textAlign: 'center'
                  }}
              >
                By signing up, you agree to the{" "}
                <a sx={{color:"#0C8CE9 !important"}} href={"https://wordofmouththerapists.com/terms"} target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and{" "}
                <a sx={{color:"#0C8CE9 !important"}} href={"https://wordofmouththerapists.com/privacy"} target="_blank" rel="noopener noreferrer">Privacy Policy.</a>                    
              </Typography>
            </Box>
      </Paper>
    </Container>
  )
}

export default Register