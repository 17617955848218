import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import CheckBox from "../CheckBox";
import { ReactComponent as WatchIcon } from "../../assets/Icons/x-watch.svg";
import {
  addDaysValues,
  addWeekValues,
  allTimesValues,
} from "../../constants/lists";
import { ReactComponent as ShieldExclamation } from "../../assets/Icons/shield-exclamation.svg";
import "./style.css";

const Availability = ({ widthIcon, sx, setData, data, error, onSave }) => {
  function handleAvailability(ev) {
    if (data) {
      const frameAvalibility = data[ev.target.name] || [];
      if (setData) {
        if (frameAvalibility.includes(ev.target.value)) {
          setData({
            [ev.target.name]: frameAvalibility.filter(
              (item) => item !== ev.target.value
            ),
          });
        } else {
          setData({
            [ev.target.name]: [...frameAvalibility, ev.target.value],
          });
        }
      }
    }
  }

  function handleWeeklyWork(ev) {
    if (setData) {
      if (ev.target.checked) {
        setData({
          office_hours_day: addWeekValues,
        });
      } else {
        setData({
          office_hours_day: [],
        });
      }
    }
  }

  function handleAllDaysWork(ev) {
    if (setData) {
      if (ev.target.checked) {
        setData({
          office_hours_day: addDaysValues,
        });
      } else {
        setData({
          office_hours_day: [],
        });
      }
    }
  }

  function handleAllDay(ev) {
    if (setData) {
      if (ev.target.checked) {
        setData({
          office_hours_time: allTimesValues,
        });
      } else {
        setData({
          office_hours_time: [],
        });
      }
    }
  }

  useEffect(() => {
    if ((data?.office_hours_day == null) || (data?.office_hours_day?.length === 0)) {
      setData({
        office_hours_time: [],
      });
    }
  }, [data?.office_hours_day])
  
  useEffect(() => {
    if (((data?.office_hours_day == null) || (data?.office_hours_day?.length === 0)) && data?.office_hours_time?.length > 0) {
      setData({
        office_hours_day: addWeekValues,
      });
    }
  }, [data?.office_hours_time])
  

  return (
    <div className="office_hours">
      <Paper sx={sx}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {widthIcon && <WatchIcon width={24} height={24} />}
          <Typography variant="h4" ml={widthIcon ? 1 : 0}>
            Office Hours
          </Typography>
        </Box>
        <Typography variant="h5">Days</Typography>

        <div className="flexDays-onbord">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            active={data?.office_hours_day?.includes("monday").toString()}
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="monday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Monday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("tuesday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="tuesday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Tuesday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("wednesday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="wednesday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Wednesday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("thursday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="thursday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Thursday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("friday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="friday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Friday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("saturday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="saturday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Saturday
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            active={data?.office_hours_day?.includes("sunday").toString()}
            size="large"
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            value="sunday"
            onClick={handleAvailability}
            name="office_hours_day"
            disableRipple
          >
            Sunday
          </Button>
        </div>

        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" row>
          <FormControlLabel
            label="All days"
            onChange={handleAllDaysWork}
            checked={data?.office_hours_day?.length === 7}
            value="all-days"
            control={<Radio />}
          />
          <FormControlLabel
            value="all-weeks"
            control={<Radio />}
            label="All weekdays"
            onChange={handleWeeklyWork}
            checked={
              data?.office_hours_day?.length > 0 &&
              data?.office_hours_day?.reduce((acc, item, _, arr) => {
                if (
                  arr.length < 5 ||
                  ![
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                  ].includes(item)
                )
                  acc = false;
                return acc;
              }, true)
            }
          />
        </RadioGroup>

        <Typography variant="h5">Working hours</Typography>
        <div className="flexHours">
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            active={data?.office_hours_time?.includes("morning").toString()}
            fullWidth
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            onClick={handleAvailability}
            value="morning"
            name="office_hours_time"
            disableRipple
          >
            Morning
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            size="large"
            active={data?.office_hours_time?.includes("afternoon").toString()}
            fullWidth
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            onClick={handleAvailability}
            value="afternoon"
            name="office_hours_time"
            disableRipple
          >
            Afternoon
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            size="large"
            active={data?.office_hours_time?.includes("evening").toString()}
            fullWidth
            sx={{
              borderRadius: 15,
              minWidth: 110,
              fontWeight: 600,
              color: "text.primary",
            }}
            onClick={handleAvailability}
            value="evening"
            name="office_hours_time"
            disableRipple
          >
            Evening
          </Button>
        </div>
        <div className="all_times">
          <CheckBox
            label="All times "
            onChange={handleAllDay}
            checked={data?.office_hours_time?.length === 3}
          />
        </div>

        {error && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#FAD4D4",
              borderRadius: 1,
              border: "1px solid #EE6363",
              mb: 1.5,
              mt: -1.5,
              py: 0.5,
              px: 3,
              alignItems: "center",
              color: "text.primary",
            }}
            icon={<ShieldExclamation />}
          >
            You have to select day frame availability and time frame
            availability
          </Alert>
        )}

        {!!onSave && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                minWidth: 150,
              }}
              onClick={onSave}
            >
              Save
            </Button>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default Availability;
