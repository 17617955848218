import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReactComponent as CorrectGreen } from "../../../../../assets/Icons/correctGreen.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { useOnboarding } from "../../../../../contexts/onboarding";

const SpecialSubsciptionPlans = ({ handleCheckout }) => {
  const { updateOnboardingState, onboardingState, fetchPlans, Specialplans } =
    useOnboarding();

  const selectSubsciptionPlan = (id) => {
    updateOnboardingState({ payment_method_id: id });
  }

  useEffect(() => {
    fetchPlans({ isSpecial: true });
    updateOnboardingState({
      payment_method_id: Specialplans[Specialplans.length - 1]?.id,
    });
  }, [Specialplans.length]);

  const getPaperStyle = () => ({
    width: "95%",
    margin: 8,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingTop: "32px",
    borderRadius: "20px",
    position: "relative",
  });

  const getMostPopularStyle = () => ({
    padding: "4px 16px",
    background: "linear-gradient(81deg, #CAE9FF 0%, #FFE7E0 96.94%)",
    borderRadius: "20px",
    position: "absolute",
    top: "-17px",
    left: "37%",
  });

  const getContentStyle = () => ({
    fontSize: "27px",
    fontWeight: "bold",
    paddingLeft: "32px",
  });

  const getPriceStyle = () => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "32px",
  });

  const getPriceValueStyle = () => ({
    fontSize: "16px",
    fontWeight: "bold",
  });

  const getButtonBoxStyle = () => ({
    margin: "44px 32px",
  });

  const getBackgroundBoxStyle = () => ({
    background: "linear-gradient(81deg, #CAE9FF 0%, #FFE7E0 96.94%)",
    padding: "17px 17px 0px 32px",
    marginTop: "24px",
  });

  const getIncludedBoxStyle = () => ({
    paddingLeft: "32px",
    marginTop: "36px",
  });

  const getSectionTitleStyle = () => ({
    fontSize: "15px",
    fontWeight: 500,
  });

  const getIncludedItemStyle = () => ({
    fontSize: "16px",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    height: "28px",
    lineHeight: "24px",
  });

  return (
    <div
      style={{
        flexGrow: 1,
        padding: 16,
        marginLeft: "6%",
        marginRight: "6%",
        boxShadow: "#949A9E",
      }}
    >
      <Grid container spacing={2}>
        {Specialplans.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Paper
              className="payment-card"
              onClick={() => selectSubsciptionPlan(item?.id)}
              style={{
                ...getPaperStyle(),
                border:
                  +onboardingState.payment_method_id === item?.id
                    ? "2px solid #0C8CE9"
                    : "none",
              }}
            >
              {index === 2 && (
                <Box style={getMostPopularStyle()}>
                  <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Most Popular
                  </Typography>
                </Box>
              )}
              <Typography style={getContentStyle()}>
                {item.name.toUpperCase()}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#949A9E",
                  margin: "16px 0px",
                  paddingLeft: "32px",
                }}
              >
                {item.billed.toUpperCase()}
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#27272A",
                  paddingLeft: "32px",
                  paddingRight: "32px",
                }}
              >
                {index !== 2
                  ? "Level up your business with a professional referrals system and more service providers. "
                  : "Get the best deals with a professional referrals system and our lowest fees"}
              </Typography>
              <Box style={getPriceStyle()}>
                <Typography
                  style={{
                    fontSize: "64px",
                    fontWeight: 700,
                    color: "#191D23",
                    marginTop: "12px",
                  }}
                >
                  ${item.price.toString().split(".")[0]}
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginLeft: "-9px",
                    }}
                  >
                    {" "}
                    .{item.price.toString().split(".")[1]}
                  </span>
                </Typography>
                <Box
                  style={{
                    fontSize: "27px",
                    fontWeight: "bold",
                    paddingLeft: "8px",
                  }}
                >
                  <Typography style={getPriceValueStyle()}>USD</Typography>
                  <Typography style={getPriceValueStyle()}>/Month</Typography>
                </Box>
              </Box>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  textDecoration: "line-through",
                  paddingLeft: "32px",
                }}
              >
                {index === 0 ? "" : index === 1 ? "$39.99" : "$39.99"}
              </Typography>
              <Box style={getBackgroundBoxStyle()}>
                {index === 2 ? (
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#D85442",
                      marginBottom: "17px",
                      textAlign: "center",
                    }}
                  >
                    SAVE 70%
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#191D23",
                      marginBottom: "17px",
                    }}
                  >
                    Get your first 2 months for Free
                  </Typography>
                )}
              </Box>
              <Box style={getIncludedBoxStyle()}>
                <Typography style={getSectionTitleStyle()}>
                  What's included
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Send referrals
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Receive referrals
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Update availability
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Send announcements
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Receive announcements
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage your network
                </Typography>
                <Typography style={getIncludedItemStyle()}>
                  <CorrectGreen />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email support
                </Typography>
                {index > 0 ? (
                  <Typography style={getIncludedItemStyle()}>
                    <CorrectGreen />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Priority email support
                  </Typography>
                ) : (
                  <Typography style={getIncludedItemStyle()}></Typography>
                )}
                {index > 1 ? (
                  <Typography style={getIncludedItemStyle()}>
                    <CorrectGreen />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free business strategy call
                  </Typography>
                ) : (
                  <Typography style={getIncludedItemStyle()}></Typography>
                )}
              </Box>
              <Box style={getButtonBoxStyle()}>
                <LoadingButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation()
                    if (+onboardingState.payment_method_id === item?.id) {
                      handleCheckout()
                      return
                    } else
                      selectSubsciptionPlan(item?.id)
                    return

                  }}
                >
                  Join Now
                </LoadingButton>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SpecialSubsciptionPlans;
