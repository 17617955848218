import { axiosInstance } from "./axiosInstance";

function getUserProfile(data) {
    return axiosInstance.get('profile/show', { params: data });
}

function favouriteUser(data) {
    return axiosInstance.post('favorites/like', data);
}

function unfavouriteUser(data) {
    return axiosInstance.post('favorites/unlike', data);
}

function sendToFriend(data) {
    return axiosInstance.post('send-profile-to-friend', data);
}

export {
    getUserProfile,
    favouriteUser,
    unfavouriteUser,
    sendToFriend
}