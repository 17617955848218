import React from 'react';
import CheckBox from '../CheckBox';
import Grid from '@mui/material/Grid';

const CheckboxListItem = ({ data, selectItem, selectedItems }) => {
    function handleSelect() {
        selectItem(data);
    }

    return (
        <Grid item xs={6}>
            <CheckBox
                label={data.name}
                onChange={handleSelect}
                checked={!!selectedItems.find((item) => +item === data.id)}
            />
        </Grid>
    )
}

export default CheckboxListItem