import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { deleteRegistration, register, registerFacility, subscriptionConfirm, subscriptionPlans } from '../services/auth.services';
import { ERROR, LOADING, SUCCESS, domain, treatment_program } from '../constants/common';
import { useAlert } from './Alert';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router-dom';
import paths from '../constants/paths';

const cookies = new Cookies();
const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 1);

const OnboardingContext = createContext({});

export const OnboardingContextProvider = ({ children }) => {
  const { updateAuthState } = useAuth();
  const { sendAlert } = useAlert();
  const [step, setStep] = useState(JSON.parse(localStorage.getItem('step') || '1'));
  const [invitatedUser, setInvitatedUser] = useState(JSON.parse(null));
  const navigate = useNavigate();

    const initialState = {
        payment_method_id: "",
        error: false,
    }

  const [onboardingState, setOnboardingState] = useState(JSON.parse(localStorage.getItem('onboardingData') || JSON.stringify(initialState)));

  const [registerStatus, setRegisterStatus] = useState('');
  const [uuid, setUuid] = useState(localStorage.getItem('uuid') || '');
  const [plans, setPlans] = useState({
    Specialplans: [],
    plans: [],
    plansFetchStatus: '',
    SpecialPlansFetchStatus: '',
  });

  const updateOnboardingState = (obj) => {
    setOnboardingState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  const next = () => {
    setStep((prevState) => prevState + 1)
  }

  const prev = () => {
    setStep((prevState) => prevState > 1 ? prevState - 1 : prevState)
  }

  const fetchPlans = async (data) => {
    setPlans((prevState) => ({
      ...prevState,
      plansFetchStatus: LOADING
    }));
    try {
      const response = await subscriptionPlans(data);
      if (!data?.isSpecial) {
        setPlans((prevState) => ({
          ...prevState,
          plans: response.data.data,
          plansFetchStatus: SUCCESS
        }));
      } else {
        setPlans((prevState) => ({
          ...prevState,
          Specialplans: response.data.data,
          SpecialPlansFetchStatus: SUCCESS
        }));
      }

    } catch (error) {
      if (!data?.isSpecial) {
        setPlans((prevState) => ({
          ...prevState,
          SpecialPlansFetchStatus: ERROR
        }));
      } else {
        setPlans((prevState) => ({
          ...prevState,
          plansFetchStatus: ERROR
        }));
      }

      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

  const loginAfterRegister = (data) => {
    localStorage.setItem('accessToken', data.token);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('account_type', data?.account_type);
    updateAuthState('account_type', data?.account_type);
    delete data.token;
    localStorage.removeItem('onboardingData')
    localStorage.removeItem('step')
    setUuid('');
    setRegisterStatus(SUCCESS)
    setStep(1);
    setOnboardingState(initialState);
    updateAuthState('userData', data);
    updateAuthState('isLoggedIn', true);
    cookies.set('isLoggedIn', 'true', { expires: expirationDate, domain: `.${domain}`, secure: true });
  }

  const signUp = async (data, isTreatment) => {
    setRegisterStatus(LOADING)
    if (invitatedUser) {
      data["register_from_invitation"] = true
      data["token"] = invitatedUser.token
    }
    try {
      const response = data.account_type === treatment_program ? await registerFacility(isTreatment ? { ...data, can_login: 0 } : data) : await register(data);
      if (isTreatment) {
        navigate(paths.info);
      } else {
        if (data.account_type === treatment_program) {
          sendAlert('Registered successfully', 'success');
          loginAfterRegister(response?.data?.data);
        }
        setRegisterStatus(SUCCESS)
      }
      return response;
    } catch (error) {
      setRegisterStatus(ERROR)
      const errorsValues = error.response?.data?.errors && Object.keys(error.response?.data?.errors || {}).map((key) => {
        if (typeof error.response?.data?.errors[key] === 'object') {
          if (Array.isArray(error.response?.data?.errors[key])) {
            return error.response?.data?.errors[key]
          }
          return '';
        }
        return error.response?.data?.errors[key]
      })
      sendAlert(error.response?.data?.message || errorsValues, 'error')
      Promise.reject(error)
    }
  }

  const confirmSubscription = async (data, registerData) => {
    setRegisterStatus(LOADING)
    try {
      const response = await subscriptionConfirm(data);
      sendAlert('Registered successfully', 'success');
      loginAfterRegister(registerData);

      return response;
    } catch (error) {
      setRegisterStatus(ERROR)
      sendAlert(error.response?.data?.message, 'error')
      await deleteRegistration(uuid);
      Promise.reject(error)
    }
  }

  const updatesetInvitatedUser = (data) => {
    setInvitatedUser(data)
  }

  const state = {
    step,
    next,
    prev,
    onboardingState,
    uuid,
    updateOnboardingState,
    setOnboardingState,
    registerStatus,
    signUp,
    fetchPlans,
    confirmSubscription,
    ...plans,
    updatesetInvitatedUser
  };

  useEffect(() => {
    localStorage.setItem('onboardingData', JSON.stringify(onboardingState))
  }, [onboardingState])

  useEffect(() => {
    if (!uuid) {
      const newUuid = uuidv4()
      localStorage.setItem('uuid', newUuid);
      setUuid(newUuid);
    }
  }, [uuid])

  useEffect(() => {
    localStorage.setItem('step', JSON.stringify(step))
  }, [step])

  return (
    <OnboardingContext.Provider value={state}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);