import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { useOnboarding } from '../../../contexts/onboarding';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { useCommon } from '../../../contexts/common';
import { detox, payment_options, services } from '../../../constants/common';

const ServicesAvailable = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { next, onboardingState, updateOnboardingState } = useOnboarding();
  const {
    fetchTreatmentTypes,
    servicesList,
    detoxList,
    paymentOptionsList,
  } = useCommon();

  const handleInput = (ev) => {
    updateOnboardingState({ [ev.target.name]: ev.target.value })
  }

  const isValid = () => {
    return onboardingState.available_services
      && Boolean((onboardingState.facility_type_ids || []).find((item) =>
        [23, 29].includes(item))) ? onboardingState.detox_for : true
    && onboardingState.payment_options
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid())
      next()
    else
      setIsInvalid(true)
  }
  useEffect(() => {
    fetchTreatmentTypes(services);
    fetchTreatmentTypes(detox);
    fetchTreatmentTypes(payment_options);
  }, [])

  return (
    <Container maxWidth='md'>
      <Paper
        sx={{
          p: 6,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h5' mb={1.5}>
          Services Available
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="available-services-label">Available Services</InputLabel>
              <Select
                labelId="available-services-label"
                label='Available Services'
                fullWidth
                name='available_services'
                multiple
                renderValue={(selected) => selected.map((val) => servicesList.find((item) => item.id === +val)?.name).join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                IconComponent={(props) => (
                  <Box
                    {...props}
                    component='span'
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '50% !important',
                      right: '16px !important',
                      transform: 'translateY(-50%) !important',
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                )}
                onChange={handleInput}
                value={onboardingState.available_services || []}
                error={isInvalid && (onboardingState.available_services || []).length < 1}
              >
                <MenuItem
                  sx={{
                    p: 0
                  }}
                  value={null}
                  disabled
                />
                {servicesList.map((item) => (
                  <MenuItem
                    sx={{
                      py: 1,
                      gap: 1
                    }}
                    value={item.id}
                    key={item.id}
                  >
                    <Checkbox sx={{ p: 0 }} checked={Boolean((onboardingState.available_services || []).find((val) => +val === item.id))} />
                    <Typography variant='body2'>{item.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {Boolean((onboardingState.facility_type_ids || []).find((item) => [23, 29].includes(item))) && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="detox-for-label">DETOX For</InputLabel>
                <Select
                  labelId="detox-for-label"
                  label='DETOX For'
                  fullWidth
                  name='detox_for'
                  multiple
                  renderValue={(selected) => selected.map((val) => detoxList.find((item) => item.id === +val)?.name).join(', ')}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                      },
                    },
                  }}
                  IconComponent={(props) => (
                    <Box
                      {...props}
                      component='span'
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '50% !important',
                        right: '16px !important',
                        transform: 'translateY(-50%) !important',
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  )}
                  onChange={handleInput}
                  value={onboardingState.detox_for || []}
                  error={isInvalid && (onboardingState.detox_for || []).length < 1}
                >
                  <MenuItem
                    sx={{
                      p: 0
                    }}
                    value={null}
                    disabled
                  />
                  {detoxList.map((item) => (
                    <MenuItem
                      sx={{
                        py: 1,
                        gap: 1
                      }}
                      value={item.id}
                      key={item.id}
                    >
                      <Checkbox sx={{ p: 0 }} checked={Boolean((onboardingState.detox_for || []).find((val) => +val === item.id))} />
                      <Typography variant='body2'>{item.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="payment-options-label">Payment options</InputLabel>
              <Select
                labelId="payment-options-label"
                label='Payment options'
                fullWidth
                name='payment_options'
                multiple
                renderValue={(selected) => selected.map((val) => paymentOptionsList.find((item) => item.id === +val)?.name).join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                IconComponent={(props) => (
                  <Box
                    {...props}
                    component='span'
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '50% !important',
                      right: '16px !important',
                      transform: 'translateY(-50%) !important',
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                )}
                onChange={handleInput}
                value={onboardingState.payment_options || []}
                error={isInvalid && (onboardingState.payment_options || []).length < 1}
              >
                <MenuItem
                  sx={{
                    p: 0
                  }}
                  value={null}
                  disabled
                />
                {paymentOptionsList.map((item) => (
                  <MenuItem
                    sx={{
                      py: 1,
                      gap: 1
                    }}
                    value={item.id}
                    key={item.id}
                  >
                    <Checkbox sx={{ p: 0 }} checked={Boolean((onboardingState.payment_options || []).find((val) => +val === item.id))} />
                    <Typography variant='body2'>{item.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Box pt={5} textAlign='center'>
        <Button
          variant='contained'
          sx={{
            minWidth: 212
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default ServicesAvailable;