import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import { ReactComponent as RadioButtonIcon } from "../../../assets/Icons/radio-button.svg";
import { ReactComponent as RadioButtonMarkedIcon } from "../../../assets/Icons/radio-button-marked.svg";
import { useOnboarding } from "../../../contexts/onboarding";
import {
  independent_mental_health_professional,
  referring_physician,
  treatment_program,
} from "../../../constants/common";
import { ReactComponent as ShieldExclamation } from "../../../assets/Icons/shield-exclamation.svg";
import "./style.css";

const BusinessDescription = ({ payment, TP }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { next, onboardingState, updateOnboardingState, setOnboardingState } =
    useOnboarding();

  function clearUserData() {
    setOnboardingState((prevState) => ({
      email: prevState.email,
      password: prevState.password,
      first_name: prevState.first_name,
      last_name: prevState.last_name,
      account_type: prevState.account_type,
    }))
  }

  function handleIndependentAccount() {
    updateOnboardingState({
      account_type: independent_mental_health_professional,
    });
    if (onboardingState.account_type === treatment_program) clearUserData();
  }

  function handleReferralAccount() {
    updateOnboardingState({ account_type: referring_physician });
    if (onboardingState.account_type === treatment_program) clearUserData();
  }

  function handleTreatmentAccount() {
    updateOnboardingState({ account_type: treatment_program });
    if (onboardingState.account_type !== treatment_program) clearUserData();
  }

  function isValid() {
    return onboardingState.account_type;
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) next();
    else setIsInvalid(true);
  }

  return (
    <Container maxWidth="md">
      <div className="cardShadow">
        <Typography variant="h5" mb={3}>
          Which of the following describes your business:
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          disabled={TP === "1"}
          startIcon={
            onboardingState.account_type ===
            independent_mental_health_professional ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handleIndependentAccount}
        >
          Independent Mental Health Professional
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          disabled={TP === "1"}
          startIcon={
            onboardingState.account_type === referring_physician ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handleReferralAccount}
        >
          Referring Physician
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          disabled={payment === "Special_Offer"}
          startIcon={
            onboardingState.account_type === treatment_program ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handleTreatmentAccount}
        >
          Treatment Program
        </Button>
        {/* )} */}

        {isInvalid && !onboardingState.account_type && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#FAD4D4",
              borderRadius: 1,
              border: "1px solid #EE6363",
              mb: 1.5,
              mt: -1.5,
              py: 0.5,
              px: 3,
              alignItems: "center",
              color: "text.primary",
            }}
            icon={<ShieldExclamation />}
          >
            You have to select one of the previous options
          </Alert>
        )}
      </div>

      <Box pt={5} textAlign="center">
        <Button
          className="btnContinue"
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit}
          disabled={!onboardingState.account_type}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default BusinessDescription;
