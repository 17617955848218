import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { ERROR, SUCCESS } from '../../constants/common'
import { useNetwork } from '../../contexts/NetWork'
import { useHomePage } from '../../contexts/HomePage'
import LoadingButton from '@mui/lab/LoadingButton'
import paths from '../../constants/paths'
import Button from "@mui/material/Button";
import { ReactComponent as AddIcon } from "../../assets/Icons/add.svg";

const UserVerticalCard = ({ data }) => {
    const { connectWithOthers, connectStatus, updateNetworkState } = useNetwork();
    const { fetchSuggestedPeople } = useHomePage();
    const [loading, setLoading] = useState(false);

    function connect() {
        setLoading(true)
        connectWithOthers({ connected_user_id: data.id });
    }

    useEffect(() => {
        if (connectStatus === SUCCESS) {
            updateNetworkState('connectStatus', '');
            fetchSuggestedPeople();
        }
        if ([SUCCESS, ERROR].includes(connectStatus))
            setLoading(false);
    }, [connectStatus])
    
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1
            }}
        >
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
                }}
            >
                <Avatar
                    sx={{
                    width: 48,
                    height: 48,
                    }}
                    src={data?.main_image}
                />
                <Box>
                    <Link href={paths.userProfile(data?.uuid, data?.account_type)} variant='body2' fontWeight={600} color='text.primary' textTransform='capitalize'>{data.first_name} {data.last_name}</Link>
                    <Typography variant='body2' color='text.secondary'>{data?.info?.headline}</Typography>
                </Box>
            </Box>
            {/* <LoadingButton
                size='small'
                variant='contained'
                sx={{
                    py: 0.75,
                    borderRadius: 2
                }}
                onClick={connect}
                loading={loading}
            >
                Connect
            </LoadingButton> */}
            <Button
                variant="outlined"
                style={{ borderRadius: "25px" }}
                onClick={connect}
                startIcon={<AddIcon />}
              >
                Connect
              </Button>
        </Box>
    )
}

export default UserVerticalCard