import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: 'transparent',
    border: 0,
    '& .MuiAccordionSummary-root': {
        padding: 0,
    },
    '& .MuiAccordionSummary-content': {
        padding: `${theme.spacing(3)} ${theme.spacing(2.5)}`,
        margin: 0,
    },
    '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
   setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <MuiAccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={(
                <Box
                    sx={{
                        fontSize: '24px',
                        lineHeight: '28px',
                        color: '#6D6D6D',
                    }}
                >
                    {expanded === 'panel1' ? '-' : '+' }
                </Box>
            )}
        >
          <Typography variant='body1'>Can I change my plan later?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can change to a different plan at the end of an active subscription period or the free trial. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <MuiAccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={(
                <Box
                    sx={{
                        fontSize: '24px',
                        lineHeight: '28px',
                        color: '#6D6D6D',
                    }}
                >
                    {expanded === 'panel2' ? '-' : '+' }
                </Box>
            )}
        >
          <Typography variant='body1'>What is your cancellation policy?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
            You can cancel an active subscription 14 days before its expiration without being charged for a renewal. We do not provide refunds if you decide to stop using the subscription during your subscription term. You can cancel your subscription by emailing us at support@therapywordofmouth.com.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <MuiAccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            expandIcon={(
                <Box
                    sx={{
                        fontSize: '24px',
                        lineHeight: '28px',
                        color: '#6D6D6D',
                    }}
                >
                    {expanded === 'panel3' ? '-' : '+' }
                </Box>
            )}
        >
          <Typography variant='body1'>How does billing work?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
          We accept payments via credit card. If you would like to pay by ACH please send us a message at <span color={{color:"blue"}}>support@therapywordofmouth.com.</span>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <MuiAccordionSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
            expandIcon={(
                <Box
                    sx={{
                        fontSize: '24px',
                        lineHeight: '28px',
                        color: '#6D6D6D',
                    }}
                >
                    {expanded === 'panel4' ? '-' : '+' }
                </Box>
            )}
        >
          <Typography variant='body1'>Can I change my account email or password?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes, you can access and change your email or password by going to your account and selecting the “settings” section.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <MuiAccordionSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
            // expandIcon={(
            //     <Box
            //         sx={{
            //             fontSize: '24px',
            //             lineHeight: '28px',
            //             color: '#6D6D6D',
            //         }}
            //     >
            //         {expanded === 'panel5' ? '-' : '+' }
            //     </Box>
            // )}
        >
          <Typography variant='body1'>Can I get a refund if I'm not satisfied with the therapy session?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <MuiAccordionSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
            // expandIcon={(
            //     <Box
            //         sx={{
            //             fontSize: '24px',
            //             lineHeight: '28px',
            //             color: '#6D6D6D',
            //         }}
            //     >
            //         {expanded === 'panel6' ? '-' : '+' }
            //     </Box>
            // )}
        >
            <Typography variant='body1'>Are there any hidden fees or charges?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <MuiAccordionSummary
            aria-controls="panel7d-content"
            id="panel7d-header"
            // expandIcon={(
            //     <Box
            //         sx={{
            //             fontSize: '24px',
            //             lineHeight: '28px',
            //             color: '#6D6D6D',
            //         }}
            //     >
            //         {expanded === 'panel7' ? '-' : '+' }
            //     </Box>
            // )}
        >
          <Typography variant='body1'>How do I cancel my subscription or recurring payments?</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}