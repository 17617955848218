import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import { LOADING, SUCCESS } from "../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHomePage } from "../../contexts/HomePage";
import { useCommon } from "../../contexts/common";
import { useBasicInfo } from "../../contexts/BasicInfo";
import PostToMenu from "./PostToMenu";

import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { ReactComponent as FileAddIcon } from "../../assets/Icons/file-add.svg";
import { ReactComponent as PaperClipIcon } from "../../assets/Icons/paper-clip.svg";
import { ReactComponent as PhotosIcon } from "../../assets/Icons/photos.svg";
import { ReactComponent as FilesIcon } from "../../assets/Icons/files.svg";
import LinkModal from "./LinkModal";
import useResize from "../../hooks/useResize";
import { validatePostContent, validateSubject } from "../../helper/Validations";



function CreatePostModal({ open, onClose, postData }) {
  const initialState = {
    body: '',
    subject: '',
    visibility: 'anyone',
    attachments: []
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const isMobileView = useResize(600);

  const { createPost, createPostStatus, updateHomePagetate, editPostAction } = useHomePage();
  const { data } = useBasicInfo();
  const { uploadImages, uploadImageStatus, uploadFiles, uploadFilesStatus, removeAttachments, deleteAttachmentStatus } = useCommon();
  function handleOpenLinkModal() {
    setOpenLinkModal(true);
  }

  function handleCloseLinkModal() {
    setOpenLinkModal(false);
  }
  useEffect(() => {
    if (!!postData) {
      setState(res => {
        return {
          ...res,
          body: postData.body,
          subject: postData.subject,
          visibility: postData.visibility,
          attachments: [...postData.attachments.map(item => item.attachment), ...postData.links.map((item) => {
            return { ...item, type: "link" }
          })]
        }
      })
    }
  }, [postData])

  function handleClose() {
    const attachments = state.attachments?.reduce((acc, item) => {
      if (item.type !== 'link') {
        return [...acc, item.id];
      }
      return acc;
    }, []);

    if (attachments.length > 0) {
      removeAttachments({ ids: attachments })
    }

    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  async function handleImage(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const response = await uploadImages(ev.target.files[0]);
      if (response?.data?.data) {
        setState((prevState) => ({
          ...prevState,
          attachments: [
            ...(prevState.attachments || []),
            response.data.data
          ]
        }))
      }
    }
  }

  async function handleFiles(ev) {
    if (ev.target.files && ev.target.files[0]) {
      const response = await uploadFiles(ev.target.files[0]);
      if (response?.data?.data) {
        setState((prevState) => ({
          ...prevState,
          attachments: [
            ...(prevState.attachments || []),
            response.data.data
          ]
        }))
      }
    }
  }

  function addLink(data) {
    setState((prevState) => ({
      ...prevState,
      attachments: [
        ...(prevState.attachments || []),
        {
          ...data,
          type: 'link',
        }
      ]
    }))
  }

  async function removeAttachment(data, index) {
    if (data.type !== 'link') {
      await removeAttachments({ ids: [data.id] });
    }

    setState((prevState) => ({
      ...prevState,
      attachments: (prevState.attachments || []).filter((_, i) => i !== index)
    }))
  }

  function isValid() {
    return state.body
      && state.subject
      && validatePostContent(state.body)
      && validateSubject(state.subject)
  }

  function handleSubmit() {
    console.log(isValid(), validatePostContent(state.body)
      , validateSubject(state.subject))
    if (isValid()) {
      const attachments = state.attachments?.reduce((acc, item) => {
        if (item.type !== 'link') {
          return [...acc, item.id];
        }
        return acc;
      }, []);

      const links = state.attachments?.reduce((acc, item) => {
        if (item.type === 'link') {
          return [...acc, { label: item.label, url: item.url }];
        }
        return acc;
      }, []);

      if (!!postData) {
        editPostAction({ ...state, attachments, links });
      } else {
        createPost({ ...state, attachments, links });
      }
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (createPostStatus === SUCCESS) {
      updateHomePagetate('createPostStatus', '')
      setState(initialState);
      setIsInvalid(false);
      onClose();
    }
  }, [createPostStatus])

  const style = {
    position: 'absolute',
    top: isMobileView ? "40%" : '50%',
    left: isMobileView ? "38%" : '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobileView ? "100vw" : 703,
    p: 3,
    overflow: 'auto',
    maxHeight: '70vh'
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={style}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pb: 2
            }}
          >
            <Box />
            <Typography variant='h4' fontWeight={600}>Announcement post</Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0.25,
                color: 'border.dark'
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32
                }}
                src={data?.main_image}
              />
              <Box>
                <Typography variant="body2" fontWeight={600} textTransform='capitalize'>{data?.first_name} {data?.last_name}</Typography>
              </Box>
            </Box>


            <TextField
              placeholder='Subject'
              name='subject'
              onChange={handleInput}
              value={state.subject}
              error={isInvalid && !validateSubject(state.subject)}
              helperText={
                isInvalid
                && !validateSubject(state.subject)
                && "Please enter valid subject"
              }
            />

            <Box
              sx={{
                border: '1px solid',
                borderTop: 0,
                borderColor: isInvalid && !validatePostContent(state.body) ? 'red' : 'secondary.main',
                borderRadius: 1.5
              }}
            >
              <TextField
                fullWidth
                multiline
                placeholder="What do you want to talk about?"
                rows={5}
                name='body'
                onChange={handleInput}
                value={state.body}
                error={isInvalid && !validatePostContent(state.body)}
                helperText={
                  isInvalid
                  && !validatePostContent(state.body)
                }
                InputProps={{
                  sx: {
                    height: 'auto',
                    pb: 1,
                    '&.Mui-focused': {
                      fieldset: {
                        border: '1px solid !important',
                        borderColor: '#A3A6A8 !important',
                        borderBottom: '0!important',
                        borderLeft: '0!important',
                        borderRight: '0!important',
                      }
                    },
                    '&:hover': {
                      fieldset: {
                        border: '1px solid !important',
                        borderColor: '#A3A6A8 !important',
                        borderBottom: '0!important',
                        borderLeft: '0!important',
                        borderRight: '0!important',
                      }
                    },
                    fieldset: {
                      borderBottom: 0,
                      borderLeft: 0,
                      borderRight: 0,

                    }
                  }
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                {state.attachments.map((item, index) => {
                  console.log(`item.short_path ${index}`, item)
                  if (item.type === 'link') {
                    return (
                      <Paper
                        sx={{
                          p: 0.75,
                          mx: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: 0,
                          boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.08)'
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            backgroundColor: '#F3F9FD',
                            px: 1,
                            py: 0.625,
                            color: 'text.secondary',
                            borderRadius: 0.5
                          }}
                        >
                          <PaperClipIcon />
                        </Box>
                        <Link variant="body2" color='primary.main' href={item.url}>{item.label}</Link>
                        <IconButton
                          onClick={function name() {
                            removeAttachment(item, index)
                          }}
                          sx={{
                            color: 'error.main',
                            p: 0.5,
                            ml: 'auto'
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Paper>
                    )
                  }
                  if (item.type === 'image') {
                    return (
                      <Box
                        sx={{ p: 2, position: 'relative' }}
                        key={index}
                      >
                        <IconButton
                          onClick={function name() {
                            removeAttachment(item, index)
                          }}
                          sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: 'error.light',
                            color: 'error.main',
                            p: 0.5
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <CardMedia
                          image={item.full_path}
                          component='img'
                          sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 1.5
                          }}
                        />
                      </Box>
                    )
                  }
                  return (
                    <Paper
                      sx={{
                        p: 0.75,
                        mx: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        border: 0,
                        boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.08)'
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#F3F9FD',
                          px: 1,
                          py: 0.625,
                          color: 'border.dark',
                          borderRadius: 0.5,
                        }}
                      >
                        <FilesIcon />
                      </Box>
                      <Typography variant="body2" color='primary.main'>{item?.short_path?.split('/')[1]}</Typography>
                      <IconButton
                        onClick={function name() {
                          removeAttachment(item, index)
                        }}
                        sx={{
                          color: 'error.main',
                          p: 0.5,
                          ml: 'auto'
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Paper>
                  )
                })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 1.75,
                }}
              >
                <Box component='label'>
                  <IconButton
                    sx={{
                      backgroundColor: '#F2F5F7',
                      color: 'text.secondary'
                    }}
                    component='span'
                  // disabled={Boolean(state.attachments.find((item) => item.type === 'file'))}
                  >
                    <FileAddIcon />
                  </IconButton>
                  <input style={{ display: 'none' }} type='file' onChange={handleFiles} />
                </Box>
                <IconButton
                  sx={{
                    backgroundColor: '#F2F5F7',
                    color: 'text.secondary'
                  }}
                  onClick={handleOpenLinkModal}
                // disabled={Boolean(state.attachments.find((item) => item.type === 'link'))}
                >
                  <PaperClipIcon />
                </IconButton>
                <Box component='label'>
                  <IconButton
                    sx={{
                      backgroundColor: '#F2F5F7',
                      color: 'text.secondary'
                    }}
                    component='span'
                  // disabled={Boolean(state.attachments.find((item) => item.type === 'image'))}
                  >
                    <PhotosIcon />
                  </IconButton>
                  <input style={{ display: 'none' }} type='file' accept="image/*" onChange={handleImage} />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <PostToMenu
                state={state}
                setState={setState}
              />
              <LoadingButton
                variant="contained"
                sx={{
                  minWidth: 218
                }}
                loading={createPostStatus === LOADING}
                type='submit'
                disabled={!state.body || !state.subject}
                onClick={handleSubmit}
              >
                Post
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Modal>
      <LinkModal
        open={openLinkModal}
        onClose={handleCloseLinkModal}
        addLink={addLink}
      />
    </>
  );
}

export default CreatePostModal;