import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";

import { useOnboarding } from "../../../contexts/onboarding";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../../contexts/common";
import AgesSelection from "../../AgesSelection";
import { treatment_program } from "../../../constants/common";
import "./style.css";

const ClientServed = () => {
  const { next, updateOnboardingState, onboardingState, setOnboardingState } =
    useOnboarding();
  const { clientTypesList, fetchClientTypesList } = useCommon();

  function handleSelect(ev) {
    updateOnboardingState({ [ev.target.name]: ev.target.value });
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    next();
  }

  function addLater() {
    setOnboardingState((prevState) => {
      delete prevState.client_types;
      delete prevState.age_served;
      localStorage.setItem("onboardingData", JSON.stringify(prevState));
      return prevState;
    });
    next();
  }

  useEffect(() => {
    fetchClientTypesList();
  }, []);

  useEffect(() => {
    if (((!onboardingState?.client_types) || (onboardingState?.client_types?.length == 0)) && ((onboardingState?.age_served?.length || 0) > 0)) {
      setOnboardingState((prevState) => ({
        ...prevState,
        client_types: [1],
      }));
    }
  }, [onboardingState?.age_served])

  useEffect(() => {
    if ((onboardingState?.client_types === null) || (onboardingState?.client_types?.length === 0) && ((onboardingState?.age_served?.length || 0) > 0)) {
      setOnboardingState((prevState) => ({
        ...prevState,
        age_served: [],
      }));
    }
  }, [onboardingState?.client_types])
  
  
  return (
    <Container maxWidth="md">
      <div className="clients_served">
        {onboardingState.account_type !== treatment_program && (
          <>
            <Typography variant="h4">Clients Served</Typography>
            <FormControl>
              <InputLabel id="demo-multiple-checkbox-label">
                Type of Clients*
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                input={<OutlinedInput label="Type of Clients*" />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) =>
                        clientTypesList.find((item) => id === item.id)?.name
                    )
                    .join(", ")
                }
                value={onboardingState.client_types || []}
                IconComponent={() => (
                  <Box
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                onChange={handleSelect}
                name="client_types"
              >
                {clientTypesList.map((item) => (
                  <MenuItem key={item.id} value={item.id} sx={{ gap: 1 }}>
                    <Checkbox
                      sx={{ p: 0 }}
                      checked={
                        !!(onboardingState.client_types || []).find(
                          (id) => id === item.id
                        )
                      }
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <AgesSelection
          data={onboardingState}
          setData={updateOnboardingState}
          clientTypesList={clientTypesList}
        />
      </div>

      <div className="buttons_education">
        <Button
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit}
          disabled={(onboardingState?.client_types?.length === 0) && (onboardingState?.age_served?.length > 0)}
        >
          Continue
        </Button>
      </div>
    </Container>
  );
};

export default ClientServed;
