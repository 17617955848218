import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import PhoneNumberInput from "../../PhoneNumberInput";
import PlaceAutoComplete from "../../PlaceAutoComplete";
import { TextField } from "@mui/material";

import { useOnboarding } from "../../../contexts/onboarding";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../../contexts/common";
import {
  validateName,
  validateNumeric,
  validateText,
  validateUrl,
  validateZipcode,
  validateStreetAddress,
} from "../../../helper/Validations";
import "./style.css";

const BasicInfo = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { next, onboardingState, updateOnboardingState, setOnboardingState } = useOnboarding();
  const { statesList, fetchStatesList } = useCommon();
  const [countryState, setCountryState] = useState(null);

  useEffect(() => {
    fetchStatesList();
  }, []);

  useEffect(() => {
    if (countryState) {
      const stateId = statesList.find((st) => st.name === countryState)?.id;
      updateOnboardingState({
        state: stateId,
      });
    }
  }, [countryState]);

  const handleInput = (ev) => {
    setOnboardingState({ ...onboardingState, [ev.target.name]: ev.target.value });
  };

  const isValid = () => {
    return (
      onboardingState.first_name &&
      onboardingState.last_name &&
      onboardingState.phone_number &&
      (onboardingState.website_link ? validateUrl(onboardingState.website_link) : true) &&
      onboardingState.city &&
      validateText(onboardingState.city) &&
      validateNumeric(onboardingState.state) &&
      validateZipcode(onboardingState.zip_code) &&
      validateStreetAddress(onboardingState.address1)
    );
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) next();
    else setIsInvalid(true);
  };

  const handleStreetAddress = (ev) => {
    updateOnboardingState({
      address1: ev.target?.value,
    });
  };

  const handleAddressChange = (Address) => {
    if (!Address) {
      updateOnboardingState({
        address1: "",
      });
    } else {
      setCountryState(Address?.state);
      updateOnboardingState({
        address1: Address?.location?.split(",")[0] || Address?.location,
        city: Address?.city,
        zip_code: Address?.zipCode,
        lat: Address?.lat,
        lng: Address?.lng,
      });
    }
  };

  const handlePhoneNumberChange = (formattedNumber) => {
    updateOnboardingState(formattedNumber);
  };

  return (
    <Container maxWidth="md">
      <div className="cardShadow">
        <Typography variant="h5" mb={1.5}>
          Basic Information
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item sx={{ width: "100%" }} md={6}>
            <TextField
              variant="outlined"
              label="First Name"
              fullWidth
              required
              onChange={handleInput}
              value={onboardingState.first_name || ""}
              name="first_name"
              error={isInvalid && !validateName(onboardingState.first_name)}
              helperText={
                isInvalid &&
                !validateName(onboardingState.first_name) &&
                "Please add first name"
              }
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={6}>
            <TextField
              variant="outlined"
              label="Last Name"
              required
              fullWidth
              onChange={handleInput}
              value={onboardingState.last_name || ""}
              name="last_name"
              error={isInvalid && !validateName(onboardingState.last_name)}
              helperText={
                isInvalid &&
                !validateName(onboardingState.last_name) &&
                "Please add last name"
              }
            />
          </Grid>

          <Grid item sx={{ width: "100%" }} md={6}>
            <PhoneNumberInput
              fullWidth
              variant="outlined"
              sx={{ mb: 3 }}
              value={onboardingState.phone_number || ""}
              onChange={handlePhoneNumberChange}
              error={isInvalid}
              name='phone_number'
              label='Phone number'
            />
          </Grid>

          <Grid item sx={{ width: "100%" }} md={6}>
            <TextField
              variant="outlined"
              label="Website Link"
              fullWidth
              onChange={handleInput}
              value={onboardingState.website_link || ""}
              name="website_link"
              error={
                onboardingState.website_link &&
                isInvalid &&
                !validateUrl(onboardingState.website_link)
              }
              helperText={
                onboardingState.website_link &&
                isInvalid &&
                !validateUrl(onboardingState.website_link) &&
                "Please add valid Website Link"
              }
            />
          </Grid>
        </Grid>

        <Typography variant="h5" mb={1.5}>
          Add your Address
        </Typography>

        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <PlaceAutoComplete
              variant="outlined"
              label="Street address"
              fullWidth
              onChange={handleAddressChange}
              onKeyUp={handleStreetAddress}
              defaultValue={onboardingState.address1 || ""}
              name="address1"
              error={isInvalid && !validateStreetAddress(onboardingState.address1)}
              helperText={
                isInvalid &&
                !validateStreetAddress(onboardingState.address1) &&
                "Please add valid street address"
              }
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <TextField
              select
              variant="outlined"
              label="State"
              fullWidth
              required
              SelectProps={{
                IconComponent: () => (
                  <Box
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                ),
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 220,
                      width: 250,
                    },
                  },
                },
              }}
              onChange={handleInput}
              value={onboardingState.state || 0}
              name="state"
              error={isInvalid && !validateNumeric(onboardingState.state)}
              helperText={
                isInvalid &&
                !validateNumeric(onboardingState.state) &&
                "Please select state"
              }
            >
              <MenuItem value={0} disabled>
                Select a state
              </MenuItem>
              {statesList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <TextField
              variant="outlined"
              label="City"
              fullWidth
              required
              onChange={handleInput}
              value={onboardingState.city || ""}
              name="city"
              error={isInvalid && !validateText(onboardingState.city)}
              helperText={
                isInvalid &&
                !validateText(onboardingState.city) &&
                "Please add valid city"
              }
            />
          </Grid>
          <Grid item sx={{ width: "100%" }} md={4}>
            <TextField
              variant="outlined"
              label="ZIP code"
              fullWidth
              required
              onChange={handleInput}
              value={onboardingState.zip_code || ""}
              name="zip_code"
              error={isInvalid && !validateZipcode(onboardingState.zip_code)}
              helperText={
                isInvalid &&
                !validateZipcode(onboardingState.zip_code) &&
                "Please add a valid Zip code"
              }
            />
          </Grid>
        </Grid>
      </div>

      <div className="stickyButton">
        <Box textAlign="center">
          <Button
            className="btnContinue"
            variant="contained"
            sx={{
              minWidth: 212,
            }}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </div>
    </Container>
  );
};

export default BasicInfo;
