import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CheckBox from "../CheckBox";
import { agesValues } from "../../constants/lists";
import { ReactComponent as ShieldExclamation } from "../../assets/Icons/shield-exclamation.svg";

const AgesSelection = ({
  setData,
  data,
  isInvalid,
  title,
}) => {
  function handleAgeServed(ev) {
    const ages = data[ev.target.name] || [];
    if (ages.includes(ev.target.value)) {
      setData({
        [ev.target.name]: ages.filter((item) => item !== ev.target.value),
      });
    } else {
      setData({
        [ev.target.name]: [...ages, ev.target.value],
      });
    }
  }

  function handleAllAges(ev) {
    if (ev.target.checked) {
      setData({
        age_served: agesValues,
      });
    } else {
      setData({
        age_served: [],
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="h5">{title || "Select Ages Served"}</Typography>

      <Box className="flexHours">
        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("0-4").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="0-4"
          onClick={handleAgeServed}
          name="age_served"
        >
          0-4
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("5-9").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="5-9"
          onClick={handleAgeServed}
          name="age_served"
        >
          5-9
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("10-12").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="10-12"
          onClick={handleAgeServed}
          name="age_served"
        >
          10-12
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("13-15").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="13-15"
          onClick={handleAgeServed}
          name="age_served"
        >
          13-15
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("16-18").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="16-18"
          onClick={handleAgeServed}
          name="age_served"
        >
          16-18
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("+19").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="+19"
          onClick={handleAgeServed}
          name="age_served"
        >
          +19
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("19-22").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="19-22"
          onClick={handleAgeServed}
          name="age_served"
        >
          19-22
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("23-65").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="23-65"
          onClick={handleAgeServed}
          name="age_served"
        >
          23-65
        </Button>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          active={data.age_served?.includes("65+").toString()}
          sx={{
            borderRadius: 15,
            fontWeight: 600,
            color: "text.primary",
            whiteSpace: "nowrap",
          }}
          value="65+"
          onClick={handleAgeServed}
          name="age_served"
        >
          65+
        </Button>
      </Box>

      <CheckBox
        label="All ages"
        checked={data.age_served?.length === 9}
        onChange={handleAllAges}
      />

      {isInvalid && (data.age_served || []).length === 0 && (
        <Alert
          severity="error"
          sx={{
            backgroundColor: "#FAD4D4",
            borderRadius: 1,
            border: "1px solid #EE6363",
            mb: 1.5,
            mt: -1.5,
            py: 0.5,
            px: 3,
            alignItems: "center",
            color: "text.primary",
          }}
          icon={<ShieldExclamation />}
        >
          You have to select the ages of the served clients
        </Alert>
      )}
    </Box>
  );
};

export default AgesSelection;
