import { ReactComponent as UserProtectionIcon } from '../assets/Icons/UserProtection.svg';
import { ReactComponent as UsersIcon } from '../assets/Icons/users.svg';
import { ReactComponent as EarthIcon } from '../assets/Icons/earth.svg';

export const ages = [
    {value: '0-4', label: '0-4'},
    {value: '5-9', label: '5-9'},
    {value: '10-12', label: '10-12'},
    {value: '13-15', label: '13-15'},
    {value: '16-18', label: '16-18'},
    {value: '+19', label: '+19'},
    {value: '19-22', label: '19-22'},
    {value: '23-65', label: '23-65'},
    {value: '65+', label: '65+'}
];

export const agesValues = ['0-4', '5-9', '10-12', '13-15', '16-18','+19','19-22', '23-65', '65+'];

export const weekDays = [
    {value: 'monday', label: 'Monday'},
    {value: 'tuesday', label: 'Tuesday'},
    {value: 'wednesday', label: 'Wednesday'},
    {value: 'thursday', label: 'Thursday'},
    {value: 'friday', label: 'Friday'},
    {value: 'saturday', label: 'Saturday'},
    {value: 'sunday', label: 'Sunday'},
]

export const addWeekValues = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
export const addDaysValues = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
export const allTimesValues = ['morning', 'afternoon', 'evening']

export const officeHours = [
    { value: 'morning', label: 'Morning' },
    { value: 'afternoon', label: 'Afternoon' },
    { value: 'evening', label: 'Evening' },
]

export const postToList = [
    { value: 'for_me', label: 'For Me', Icon: UserProtectionIcon },
    { value: 'first_connection_only', label: '1st Connection Only', Icon: UsersIcon },
    { value: 'anyone', label: 'Anyone', Icon: EarthIcon },
]