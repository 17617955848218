import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import { useOnboarding } from "../../../contexts/onboarding";
import Availability from "../../Availability";
import { LOADING, treatment_program } from "../../../constants/common";
import LoadingButton from "@mui/lab/LoadingButton";
import sortAgeRanges from "../../../utils/sortAges";
import "./style.css";
import { useSearchParams } from "react-router-dom";

const OnboardingAvailability = () => {
  const {
    next,
    updateOnboardingState,
    onboardingState,
    uuid,
    signUp,
    setOnboardingState,
    registerStatus,
  } = useOnboarding();
  const [searchParams] = useSearchParams();
  const TP = searchParams.get("TP");

  function handleSubmit(ev) {
    ev.preventDefault();
    if (onboardingState.account_type !== treatment_program) next();
    else {
      const age_served = onboardingState.age_served
        ? sortAgeRanges(onboardingState.age_served).join(",")
        : onboardingState.age_served?.join(",");
      const payload = {
        ...onboardingState,
        office_hours_day: onboardingState.office_hours_day?.join(","),
        office_hours_time: onboardingState.office_hours_time?.join(","),
        age_served: age_served,
        uuid,
      };
      signUp(
        payload,
        onboardingState.account_type === treatment_program && TP !== "1"
      );
    }
  }

  function addLater() {
    setOnboardingState((prevState) => {
      delete prevState.office_hours_day;
      delete prevState.office_hours_time;
      localStorage.setItem("onboardingData", JSON.stringify(prevState));
      return prevState;
    });
    next();
  }

  return (
    <Container maxWidth="md">
      <Availability
        sx={{
          padding: "2rem 1rem",
          boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.04)",
          border: 0,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
        setData={updateOnboardingState}
        data={onboardingState}
      />

      <div className="buttons_education">
        <LoadingButton
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit}
          loading={registerStatus === LOADING}
        >
          Continue
        </LoadingButton>
      </div>
    </Container>
  );
};

export default OnboardingAvailability;
