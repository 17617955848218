import { axiosInstance } from "./axiosInstance";

const getUserClientTypes = () => {
  return axiosInstance.get('client-types/user');
}

const getClientTypes = () => {
  return axiosInstance.get('client-types/with-selection');
}

const updateClientTypes = (data) => {
  return axiosInstance.post('client-types', data);
}

const updateAgesServed = (data, userType = 'info') => {
  return axiosInstance.put(`${userType}/age-served`, data);
}

export {
  getUserClientTypes,
  getClientTypes,
  updateClientTypes,
  updateAgesServed
}