export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const independent_mental_health_professional = 'independent_mental_health_professional';
export const referring_physician = 'referring_physician';
export const treatment_program = 'treatment_program';

export const accepting_new_clients_and_taking_referrals = 'accepting_new_clients';
export const full_and_need_to_refer_out = 'not_accepting_clients';
export const somewhere_in_between = 'somewhere_in_between';


//export const developmentDomain = 'wordofmouththerapists.com';
// export const developmentDomain = 'api-stage.therapywordofmouth.com';
export const developmentDomain = 'new.therapywordofmouth.com';
//export const productionDomain = 'therapywordofmouth.com';
export const productionDomain = 'www.therapywordofmouth.com';
export const developmentBaseUrl = process.env.developmentBaseUrl || `https://${developmentDomain}`; 
export const productionBaseUrl = `https://${productionDomain}`;

export const baseUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? productionBaseUrl : developmentBaseUrl;
export const domain = process.env.REACT_APP_ENVIRONMENT === 'production' ? productionDomain : developmentDomain;
export const rest_api_key = process.env.REST_API_KEY || 'HDIhhZsSXezJ9AxI0wCftIy1o4vLGfGY6fRYC9m24GdWKPGTxqg6PQM0UqD3k2Ts'
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
export const geoKey = process.env.REACT_APP_GEO_KEY;

export const imagesPath = `${baseUrl}/avatars/`;

export const apiUrl = `${baseUrl}/api/`;

export const connections= 'connections'
export const favorites= 'favorites'
export const colleagues= 'all'
export const sent= 'sent'
export const received= 'received'

export const treatment= 'treatment'
export const services= 'services'
export const detox= 'detox'
export const payment_options= 'payment_options'
export const facility_types= 'facility_types'

export const notify_favorite_colleagues = {
    value: 'notify_favorite_colleagues',
    label: 'Notify colleagues when accepting new patients'
}

export const inperson_session = {
    value: 'inperson_session',
    label: 'In-person Session'
}

export const has_video_provided = {
    value: 'has_video_provided',
    label: 'Video/Teletherapy Provided'
}

export const psypact_provider = {
    value: 'psypact_provider',
    label: 'PSYPACT Provider'
}

export const medication_prescriber = {
    value: 'has_medication_prescriber',
    label: 'Medication Prescriber'
}

export const everyone = 'everyone';
export const only_from_my_connections = 'only_from_my_connections';
export const only_from_my_favorites = 'only_from_my_favorites';

export const onlyAcceptingClientsFrom = {
    everyone: "Everyone, Only From My Connections, Only From My Favorites",
    only_from_my_connections: "Only From My Connections, Only From My Favorites",
    only_from_my_favorites: "Only From My Favorites"
}

export const sortData = (items) => {
  return items.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

export const paymentFeatures = [
  "Send referrals", 
  "Receive referrals", 
  "Update availability", 
  "Send announcements", 
  "Receive announcements", 
  "Manage your network", 
  "Email support", 
  "Priority email support", 
  "Phone support", 
  "Free business strategy call"
];
