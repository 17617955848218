import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckIcon } from '../../../../assets/Icons/check-filled.svg'
import { useUserProfile } from '../../../../contexts/UserProfile';

const PaymentInformation = () => {
    const { data } = useUserProfile();
    const private_health_insurance = Boolean(data?.info?.payment_options?.find((item) => item.key === 'private_health_insurance'))
    const free = Boolean(data?.info?.payment_options?.find((item) => item.key === 'free'))
    const medicare = Boolean(data?.info?.payment_options?.find((item) => item.key === 'medicare'))
    const medicaid = Boolean(data?.info?.payment_options?.find((item) => item.key === 'medicaid'))
    const IHSFunded = Boolean(data?.info?.payment_options?.find((item) => item.key === 'IHS-funded'))
    const TRICARE = Boolean(data?.info?.payment_options?.find((item) => item.key === 'TRICARE'))
    const state_insurance = Boolean(data?.info?.payment_options?.find((item) => item.key === 'state_insurance'))
    const sliding_fee_scale = Boolean(data?.info?.payment_options?.find((item) => item.key === 'sliding_fee_scale'))
    const payment_assistance = Boolean(data?.info?.payment_options?.find((item) => item.key === 'payment_assistance'))

    return (
        <Paper
            sx={{
                p: 3,
                mb: 2
            }}
        >
            <Typography variant='h5' fontWeight={600} mb={3}>Payment Information</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component='th' sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1' fontWeight={600}>Type</Typography></TableCell>
                        <TableCell component='th' sx={{ borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1' fontWeight={600} textAlign='center'>Accepted</Typography></TableCell>
                        <TableCell component='th' sx={{ borderColor: 'secondary.main' }}><Typography variant='body1' fontWeight={600} textAlign='center'>Not Accepted</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>Private health insurance</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {private_health_insurance && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!private_health_insurance && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>Free</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {free && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!free && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>Medicare</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {medicare && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!medicare && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>Medicaid</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {medicaid && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!medicaid && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>IHS-funded</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {IHSFunded && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!IHSFunded && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>TRICARE</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {TRICARE && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!TRICARE && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>State insurance</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {state_insurance && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!state_insurance && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main' }}><Typography variant='body1'>Sliding fee scale</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {sliding_fee_scale && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main' }}>
                            {!sliding_fee_scale && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ pl: 0, borderRight: '1px solid', borderColor: 'secondary.main', borderBottom: 0 }}><Typography variant='body1'>Payment assistance</Typography></TableCell>
                        <TableCell sx={{ borderRight: '1px solid', borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main', borderBottom: 0 }}>
                            {payment_assistance && <CheckIcon />}
                        </TableCell>
                        <TableCell sx={{ borderColor: 'secondary.main', textAlign: 'center', color: 'primary.main', borderBottom: 0 }}>
                            {!payment_assistance && <CheckIcon />}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    )
}

export default PaymentInformation