import React, { useEffect, useState } from 'react'
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTeams } from '../../contexts/Teams';
import { ERROR, SUCCESS } from '../../constants/common';

const MemberRow = ({ data, index, handleOpenConfirmationModal, length, isAccepted, resendInvitationFunction, handleOpenRevokeModal, handleOpenUpdateModal = null }) => {
  const [loading, setLoading] = useState(false);
  const {
    resendInvitationStatus,
    updateTeamsState
  } = useTeams();

  useEffect(() => {
    if (resendInvitationStatus === SUCCESS) {
      updateTeamsState('resendInvitationStatus', '');
    }
    if ([SUCCESS, ERROR].includes(resendInvitationStatus))
      setLoading(false);
  }, [resendInvitationStatus])

  const renderRole = (role) => {
    if (role === 'team_admin')
      return 'Admin'
    if (role === 'team_assistant')
      return 'Assistant'
  }

  const onDelete = () => {
    handleOpenConfirmationModal(data)
  }

  const resendInvitation = () => {
    setLoading(true);
    resendInvitationFunction(data) 
  }

  const onRevoking = () => {
      handleOpenRevokeModal(data);
  }

  // function onUpdate() {
  //     console.log(handleOpenUpdateModal)
  //     handleOpenUpdateModal();
  // }


  return (
    <TableRow key={data.id}>
      <TableCell sx={{ borderBottom: index === length ? 0 : '1px solid', borderColor: '#EEEFF0', pl: 0 }}>
        <Typography variant='body2' fontWeight={600} mb={0.5}>{isAccepted ? data.name : data?.params?.email}</Typography>
        <Typography variant='body2'>{renderRole(data?.params?.role || data?.params?.name)}</Typography>
      </TableCell>
      <TableCell sx={{ borderBottom: index === length ? 0 : '1px solid', borderColor: '#EEEFF0' }}>
        <Typography variant='body2'>{moment(data?.created_at).format("DD MMMM 'YY")}</Typography>
      </TableCell>
      <TableCell sx={{ borderBottom: index === length ? 0 : '1px solid', borderColor: '#EEEFF0' }}>
        {!!data.revoke && <Typography variant='body2'>{moment(data?.updated_at).format("DD MMMM 'YY")}</Typography>}
      </TableCell>
      <TableCell sx={{ borderBottom: index === length ? 0 : '1px solid', borderColor: '#EEEFF0', width: 320 }}>
        {isAccepted && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              sx={{
                  color: 'primary.main',
                  minWidth: 135
              }}
              onClick={onDelete}
            >
              Delete
            </Button>
            {/* 

            // Removed the button until its backend API is created
            
            <Button
                variant='outlined'
                color='secondary'
                onClick={onUpdate}
                size='small'
                sx={{
                    color: 'primary.main',
                    minWidth: 135
                }}
            >
                Edit
            </Button> 
            
            */}
          </Box>
        )}
        {!isAccepted && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <LoadingButton
              variant='outlined'
              color='secondary'
              size='small'
              sx={{
                color: 'primary.main',
                minWidth: 150
              }}
              loading={loading}
              onClick={resendInvitation}
            >
              Resend Invitation
            </LoadingButton>
            { Boolean(!data.revoke) && <Button
              variant='outlined'
              color='secondary'
              size='small'
              sx={{
                color: 'primary.main',
                minWidth: 135
              }}
              onClick={onRevoking}
            >
              Revoke
            </Button>}
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}

export default MemberRow