import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';


import Faq from './components/Faq';
import SubscriptionPlans from './components/SubscriptionPlans';
import Guides from './components/Guides';
import { useOnboarding } from '../../../contexts/onboarding';
import CheckoutModal from '../CheckoutForm';
import './style.css'
const Subsciption = () => {
  const { onboardingState, updateOnboardingState } = useOnboarding();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }

  function handleOpen() {
    if (onboardingState?.payment_method_id) {
      setOpen(true)
    } else {
      updateOnboardingState({ error: true });
    }
  }

  return (
    <>
      <SubscriptionPlans />
      <Guides />
      <Box
        sx={{
          backgroundColor: '#FAFBFC',
          py: 6
        }}
      >
        <Box
          sx={{
            maxWidth: 1031,
            mx: 'auto',
          }}
        >
          <Grid container spacing={7} sx={{ paddingRight: 3 }}>
            <Grid className='displayNone' item md={2.8} xs={12}>
              <Typography variant='body1' mb={2.5} style={{ fontWeight: "500", fontSize: "24px" }}>
                Frequently asked questions
              </Typography>
              <Typography variant='body2' style={{ fontWeight: "400", fontSize: "14px", color: "#61676B" }}>
                Haven't found what you're looking for? Please <Link href='mailto:support@therapywordofmouth.com' variant='body2'>contact us</Link>
              </Typography>
            </Grid>
            <Grid item md={9.2} xs={12}>
              <Faq />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box minHeight={86}>
        <Box
          component='footer'
          sx={{
            boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
            px: 5,
            py: 2.5,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'fixed',
            width: '100%',
            bottom: 0,
            backgroundColor: 'background.paper'
          }}
        >
          <LoadingButton
            variant='contained'
            sx={{
              minWidth: 212
            }}
            size='large'
            onClick={handleOpen}
            className='btnContinue'
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
      {open &&
        <CheckoutModal
          open={open}
          onClose={handleClose}
        />}
    </>
  )
}

export default Subsciption