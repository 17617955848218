import React, { createRef, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { ReactComponent as LikeIcon } from "../../assets/Icons/like.svg";
import { ReactComponent as LikeClicked } from "../../assets/Icons/likeClicked.svg";
import { ReactComponent as CommentIcon } from "../../assets/Icons/comment.svg";
import { ReactComponent as MenuIcom } from "../../assets/Icons/menuIcom.svg";
import { ReactComponent as EditIcon } from "../../assets/Icons/edit-Post.svg";
import { ReactComponent as DeleteIcom } from "../../assets/Icons/delete.svg";

import moment from "moment";
import paths from "../../constants/paths";
import { ERROR, SUCCESS, treatment_program } from "../../constants/common";
import { useHomePage } from "../../contexts/HomePage";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { ReactComponent as PaperClipIcon } from "../../assets/Icons/paper-clip.svg";
import { ReactComponent as FilesIcon } from "../../assets/Icons/files.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Icons/download.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/Icons/chevron-right.svg";
import ReadMore from "../ReadMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { useAlert } from "../../contexts/Alert";
import Comment from "./comment";
import scrollTo from "../../helper/scrollTo";
import { validatePostContent } from "../../helper/Validations";

const options = ["Edit Post", "Delete Post"];
const ITEM_HEIGHT = 48;

const PostCard = ({ data }) => {
  const {
    createComment,
    createCommentStatus,
    updateHomePagetate,
    togglePostLike,
    likePostStatus,
    handleOpenCreatePostModal,
    updateEditPostData,
    deletePostAction,
    fetchPosts,
    updateComment,
    updateCommentStatus
  } = useHomePage();
  const { data: info } = useBasicInfo();
  const [state, setState] = useState({ comment: "" });
  const [isliked, setIsliked] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [commentData, setComment] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  
  const { sendAlert } = useAlert();
  const commentInput = createRef(null);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const handleOnPostActionPress = (index) => {
    if (index === 0) {
      updateEditPostData(data);
      handleOpenCreatePostModal();
    } else {
      deletePostAction(data.id)
        .then((res) => {
          sendAlert(res.data.message, "success");
          fetchPosts({ page: 1 });
        })
        .catch((error) => {
          sendAlert(error.response?.data?.message, "error");
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isValid = () => {
    return state.comment && validatePostContent(state.comment)
  }

  async function handleKeypDown(ev) {
    if(ev.key === "Escape" && ev.keyCode === 27) {
      if (commentData?.id) {
        setComment(null);
        setState((prevState) => ({
          ...prevState,
          comment: ''
        }))
      }
    } else if (ev.key === "Enter" && ev.keyCode === 13) {
      if (!isValid()) {
        setIsInvalid(true);
        return;
      }
      if (!loading) {
        try {
          setLoading(true);
          if (state.comment) {
            if (commentData?.id) {
              await updateComment({ ...state, comment_id: commentData.id }, data.id)
            } else {
              await createComment({ ...state, post_id: data.id });
            }
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    }
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  const liked = data?.liked;

  function likePost() {
    setIsliked(!isliked);
    togglePostLike({ isLiked: !isliked, post_id: data.id });
  }

  function editComment(comment) {
    setComment(comment)
    setState((prevState) => ({
      ...prevState,
      comment: comment.comment,
    }));
  }

  useEffect(() => {
    if (createCommentStatus === SUCCESS) {
      updateHomePagetate("createCommentStatus", "");
      setState({ comment: "" });
    }
  }, [createCommentStatus]);

  useEffect(() => {
    if (updateCommentStatus === SUCCESS) {
      updateHomePagetate("updateCommentStatus", "");
      setState({ comment: "" });
      setComment(null)
    }
  }, [updateCommentStatus]);

  useEffect(() => {
    setIsliked(liked);
  }, [liked]);

  useEffect(() => {
    if (likePostStatus === ERROR) {
      setIsliked((prev) => !prev);
    }
  }, [likePostStatus]);

  useEffect(() => {
    if (commentData?.id && commentInput?.current) {
      commentInput?.current?.focus();
      const element = commentInput?.current;
      scrollTo(element, 100)
    }
  }, [commentData])

  return (
    <Paper
      sx={{
        boxShadow: "none",
        borderColor: "border.main",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 3,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 1,
            maxWidth: "80%",
          }}
        >
          <Avatar
            sx={{
              width: 48,
              height: 48,
            }}
            src={data?.user?.main_image}
          />
          <Box>
            <Link
              href={paths.userProfile(
                data?.user?.uuid,
                data?.user?.account_type === treatment_program &&
                  data?.user?.roles_num === 1
                  ? treatment_program
                  : "user"
              )}
              variant="body2"
              fontWeight={600}
              color="text.primary"
              textTransform="capitalize"
            >
              {data?.user?.account_type === treatment_program &&
              data?.user?.roles_num === 1
                ? data?.user?.treatment_program_info?.treatment_program
                    ?.facility_name
                : `${data?.user?.first_name} ${data?.user?.last_name}${
                    data?.user?.info?.title || data?.user?.degrees?.length > 0
                      ? `, ${
                          data?.user?.degrees?.length > 0
                            ? data?.user?.degrees[0]?.degree.name
                            : data?.user?.info?.title
                        }`
                      : ""
                  }`}
            </Link>
            <Typography
              variant="body2"
              color="text.secondary"
              component="p"
              lineHeight={1.5}
            >
              {data?.user?.info?.headline}
            </Typography>
            {/* <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={600}
              component="p"
            >
              {moment(data?.created_at).format("DD MMMM 'YY")}
            </Typography> */}
          </Box>
          {data?.user?.id === info?.id && (
            <Box style={{ position: "absolute", top: 16, right: 16 }}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcom />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    selected={option === "Pyxis"}
                    onClick={() => handleOnPostActionPress(index)}
                  >
                    {index === 0 ? <EditIcon /> : <DeleteIcom />} &nbsp;{" "}
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "inherit",
        }}
      >
        <Typography variant="body1" fontWeight={600} component="p" mb={1.5}>
          {data?.subject}
        </Typography>
        <ReadMore text={data.body} linesToShow={3} lineHeight={22} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {data?.links?.map((item) => (
            <Link href={item.url} download>
              <Paper
                sx={{
                  p: 0.75,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  border: 0,
                  boxShadow: "0px 0px 32px 0px rgba(0, 0, 0, 0.08)",
                }}
                key={item.id}
              >
                <Box
                  sx={{
                    backgroundColor: "#F3F9FD",
                    px: 1,
                    py: 0.625,
                    color: "text.secondary",
                    borderRadius: 0.5,
                  }}
                >
                  <PaperClipIcon />
                </Box>
                <Typography variant="body2" color="primary.main">
                  {item.label}
                </Typography>
                <Box
                  sx={{
                    color: "primary.main",
                    ml: "auto",
                    width: 24,
                    height: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ChevronRightIcon />
                </Box>
              </Paper>
            </Link>
          ))}

          {data?.attachments?.map((item) => {
            if (item?.attachment?.type === "image") {
              return (
                <Box key={item?.attachment_id}>
                  <CardMedia
                    image={item?.attachment?.full_path}
                    component="img"
                    sx={{
                      width: "100%",
                      height: "auto",
                      borderRadius: 1.5,
                    }}
                  />
                </Box>
              );
            }
            return (
              <Link href={item?.attachment?.full_path} download>
                <Paper
                  sx={{
                    p: 0.75,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    border: 0,
                    boxShadow: "0px 0px 32px 0px rgba(0, 0, 0, 0.08)",
                  }}
                  key={item?.attachment_id}
                >
                  <Box
                    sx={{
                      backgroundColor: "#F3F9FD",
                      px: 1,
                      py: 0.625,
                      color: "border.dark",
                      borderRadius: 0.5,
                    }}
                  >
                    <FilesIcon />
                  </Box>
                  <Typography variant="body2" color="primary.main">
                    {item?.attachment?.short_path.split("/")[1]}
                  </Typography>
                  <Box
                    sx={{
                      color: "primary.main",
                      ml: "auto",
                    }}
                  >
                    <DownloadIcon />
                  </Box>
                </Paper>
              </Link>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontWeight: 600,
            }}
          >
            <Button
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                p: 0,
                minWidth: 0,
                color: isliked ? "primary.main" : "text.priamry",
              }}
              onClick={likePost}
            >
              {isliked ? <LikeClicked /> : <LikeIcon />}
              Like
            </Button>
            {Boolean(data?.likes_count) && <>({data?.likes_count})</>}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontWeight: 600,
            }}
          >
            <Button
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                p: 0,
                minWidth: 0,
              }}
              onClick={handleExpandClick}
            >
              <CommentIcon />
              Comments
            </Button>
            {Boolean(data?.comments?.length) && <>({data?.comments?.length})</>}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            color="text.secondary"
            component="p"
            sx={{ fontSize: "14px", color: "#61676B" }}
          >
            {moment(data?.created_at).fromNow()}
          </Typography>
        </Box>
        {/* <Button
                    variant='body2'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        p: 0,
                        minWidth: 0
                    }}
                >
                    <ShareIcon />
                    Share
                </Button> */}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            pl:0,
            gap: 1,
            mb: 1,
          }}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
            src={info?.main_image}
          />
          <TextField
            // variant='filled'
            placeholder={`Reply on ${
              data?.user?.account_type === treatment_program &&
              data?.user?.roles_num === 1
                ? data?.user?.treatment_program_info?.treatment_program
                    ?.facility_name
                : data?.user?.first_name
            }`}
            fullWidth
            sx={{
              input: {
                py: 0.75,
                px: 1,
                fontSize: 14,
                lineHeight: "22px",
                maxHeight: "40px",
                "&::placeholder": {
                  fontWeight: 700,
                },
              },
            }}
            onChange={handleInput}
            name="comment"
            value={state.comment}
            inputRef={commentInput}
            onKeyDown={handleKeypDown}
            error={isInvalid && !validatePostContent(state.comment)}
            helperText={
              isInvalid
              && !validatePostContent(state.comment)
              && "Please enter valid comment"
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {data?.comments?.map((item, index) => (
            <Comment
              item={item}
              setLoading={setLoading}
              loading={loading}
              post_id={data.id}
              editComment={editComment}
            />
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default PostCard;
