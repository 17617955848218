import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import { 
  LOADING, 
  SUCCESS, 
  accepting_new_clients_and_taking_referrals, 
  everyone, 
  full_and_need_to_refer_out, 
  onlyAcceptingClientsFrom, 
  only_from_my_connections, 
  only_from_my_favorites, 
  treatment_program 
} from '../../constants/common';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg';
import AgesSelection from '../AgesSelection';
import { useCommon } from '../../contexts/common';
import { useHomePage } from '../../contexts/HomePage';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';
import sortAgeRanges from "../../utils/sortAges"

const customStyles = {
  minWidth: 24,
  width: 24,
  height: 24,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50% !important',
  right: '16px !important',
  transform: 'translateY(-50%) !important',
}

const PracticeStatusDrawer = ({ onClose, open }) => {
  const initialState = {
      current_status: '',
      age_served: [],
      client_types: [],
      accepting_referrals_from: '',
      client: [],
  }

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  
  const { clientTypesList, fetchClientTypesList } = useCommon();
  const { data } = useBasicInfo();
  const { facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const info = account_type === treatment_program ? facilityInfo : data;

  const {
      editCurrentStatus,
      updateCurrentStatusStatus,
      updateHomePagetate
  } = useHomePage();

  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  const handleSelect = (ev) => {
    console.log(ev.target);
    const {name, value} = ev.target;
    updateState({ [name]: value})
  }

  const handleClose = () => {
    setState(initialState);
    setIsInvalid(false);
    onClose();
  }

  const isValid = () => {
    return state.current_status
      && state.age_served.length > 0
      && (account_type !== treatment_program ? state.client_types.length > 0 : true)
      && state.accepting_referrals_from
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) {
      const acceptingPayLoad = account_type !== treatment_program ? 
        {
          ...state,
          age_served:state.age_served?.length ? sortAgeRanges(state.age_served).join(',') : [],
          account_type: account_type === treatment_program ? treatment_program : 'user',
          currently_served: state?.client
        } : 
        {
          current_status: state.current_status,
          accepting_referrals_from: state.accepting_referrals_from,
          age_served:state.age_served?.length ? sortAgeRanges(state.age_served).join(',') : [],
          account_type: account_type === treatment_program ? treatment_program : 'user',
        }
      
      const payload = state.current_status === accepting_new_clients_and_taking_referrals ? 
        acceptingPayLoad : 
        {
          current_status: state.current_status,
          account_type: account_type === treatment_program ? treatment_program : 'user',
        }
      
      editCurrentStatus(payload)
    } else {
      setIsInvalid(true)
    }
  }

  useEffect(() => {
      fetchClientTypesList();
  }, [])
  
  useEffect(() => {
    if (updateCurrentStatusStatus === SUCCESS) {
      updateHomePagetate('updateCurrentStatusStatus', '');
      handleClose();
    }
  }, [updateCurrentStatusStatus])

  useEffect(() => {
    if (open) {
      setState(({
        current_status: info?.info?.current_status || '',
        age_served: (info?.info?.age_served || '').split(','),
        client_types: (info?.client_types || []).map(({ id }) => id),
        accepting_referrals_from: info?.info?.accepting_referrals_from || '',
        client:(info?.client_types || [])
        .filter(item => item?.currently_served)
        .map(item => item?.id)
      }))
    }

  }, [info, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            pb: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            px: 3
          }}
        >
          <Typography variant='h4'>
            {
              state.current_status !== full_and_need_to_refer_out ? 
                'What type of clients are you currently accepting?' : 
                'Are you currently accepting new clients?'
            }
          </Typography>
          <Divider />
          <FormControl fullWidth>
            <InputLabel id="practice-status-label">Practice Status</InputLabel>
            <Select
              labelId="practice-status-label"
              label='Practice Status'
              fullWidth
              renderValue={(selected) => {
                if (selected === accepting_new_clients_and_taking_referrals)
                  return 'Accepting New Clients'
                if (selected === full_and_need_to_refer_out)
                  return 'Not Accepting Clients'
              }}
              name='current_status'
              IconComponent={(props) => (
                <Box
                  {...props}
                  component='span'
                  sx={customStyles}
                >
                  <ChevronDown width={12} height={6} />
                </Box>
              )}
              onChange={handleSelect}
              value={state.current_status}
              error={isInvalid && !state.current_status}
            >
              <MenuItem
                sx={{
                  p: 0
                }}
                value={null}
                disabled
              />
              <MenuItem
                value={accepting_new_clients_and_taking_referrals}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  py: 1
                }}
              >
                <Typography variant='body2'>Accepting New Clients</Typography>
                <Typography variant='caption' color='text.secondary'>Accepting new clients and taking referrals</Typography>
    
              </MenuItem>
    
              <Divider sx={{ my: 1 }} />
              <MenuItem
                value={full_and_need_to_refer_out}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  py: 1
                }}
              >
                <Typography variant='body2'>Not Accepting Clients</Typography>
                <Typography variant='caption' color='text.secondary'>Full and need to refer out</Typography>
              </MenuItem>
            </Select>
          </FormControl>
          {state.current_status !== full_and_need_to_refer_out && (
            <>
              {account_type !== treatment_program && (
                <FormControl fullWidth>
                  <InputLabel id="clients-type-label">Client Types</InputLabel>
                  <Select
                    labelId="clients-type-label"
                    id="clients-types"
                    label='Client Types'
                    multiple
                    renderValue={(selected) => selected.map((id) => clientTypesList.find((item) => id === item.id)?.name).join(', ')}
                    value={state.client_types || []}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component='span'
                        sx={customStyles}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    name='client_types'
                    onChange={handleSelect}
                    error={isInvalid && state.client_types.length < 1}
                  >
                    {clientTypesList.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{ gap: 1 }}
                      >
                        <Checkbox sx={{ p: 0 }} checked={!!(state.client_types || []).find((id) => id === item.id)} />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {account_type !== treatment_program && (
                <FormControl fullWidth>
                  <InputLabel id="client-label">Currently served</InputLabel>
                  <Select
                    labelId="client-label"
                    placeholder='Client'
                    id="client"
                    label='Currently Served'
                    multiple
                    renderValue={(selected) => {
                      return selected.map((id) => clientTypesList.find((item) => id === item.id)?.name).join(', ')} }
                    value={state.client || []}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component='span'
                        sx={customStyles}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    name='client'
                    onChange={handleSelect}
                    error={isInvalid && state.client.length < 1}
                  >
                    {clientTypesList.filter(obj1 =>
                      state.client_types?.some(id => id === obj1.id)
                    ).map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        sx={{ gap: 1 }}
                      >
                        <Checkbox sx={{ p: 0 }} checked={!!(state.client || []).find((id) => id === item.id)} />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <AgesSelection
                data={state}
                setData={updateState}
                title='Ages Served'
                isInvalid={isInvalid}
              />
              <FormControl fullWidth>
                <InputLabel id="accepting-clients-label">Who are you accepting clients from?</InputLabel>
                <Select
                  labelId="accepting-clients-label"
                  label='Who are you accepting clients from?'
                  fullWidth
                  renderValue={(selected) => {
                    if (onlyAcceptingClientsFrom[selected]) {
                      return <>{onlyAcceptingClientsFrom[selected]}</>
                    }
                  }}
                  IconComponent={(props) => (
                    <Box
                      {...props}
                      component='span'
                      sx={customStyles}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  )}
                  onChange={handleSelect}
                  name='accepting_referrals_from'
                  value={state.accepting_referrals_from}
                  error={isInvalid && !state.accepting_referrals_from}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                      },
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      p: 0
                    }}
                    value={null}
                    disabled
                  />
                  <MenuItem
                    value={everyone}
                    sx={{ gap: 1 }}
                  >
                    <Checkbox sx={{ p: 0 }} checked={state.accepting_referrals_from === everyone} />
                    Everyone
                  </MenuItem>
                  <MenuItem
                    value={only_from_my_connections}
                    sx={{ gap: 1 }}
                  >
                    <Checkbox sx={{ p: 0 }} checked={[everyone, only_from_my_connections].includes(state.accepting_referrals_from)} />
                    Only From My Connections
                  </MenuItem>
                  <MenuItem
                    value={only_from_my_favorites}
                    sx={{ gap: 1 }}
                  >
                    <Checkbox sx={{ p: 0 }} checked={[everyone, only_from_my_connections, only_from_my_favorites].includes(state.accepting_referrals_from)} />
                    Only From My Favorites
                  </MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Box>
              
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateCurrentStatusStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default PracticeStatusDrawer;