import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { useOnboarding } from '../../../contexts/onboarding';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { ReactComponent as UploadIcon } from '../../../assets/Icons/upload.svg'
import { useCommon } from '../../../contexts/common';
import { useEffect } from 'react';
import { facility_types } from '../../../constants/common';
import {
  validateNumeric,
  validateOrganizationName,
  validatePhoneNumber,
  validateStreetAddress,
  validateText,
  validateUrl,
  validateZipcode
} from '../../../helper/Validations';
import PlaceAutoComplete from '../../PlaceAutoComplete';
import PhoneNumberInput from '../../PhoneNumberInput';

const TreatmentProgramProfile = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { next, onboardingState, updateOnboardingState, setOnboardingState } = useOnboarding();
  const { statesList, fetchStatesList, fetchTreatmentTypes, fetchFacilityTypesStatus, facilityTypesList } = useCommon();
  const [countryState, setCountryState] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState(null);

  useEffect(() => {
    if (countryState) {
      const stateId = statesList.filter((st) => st.name == countryState)[0]?.id;
      updateOnboardingState({
        state: stateId,
      });
    }
  }, [onboardingState.address1]);

  useEffect(() => {
    fetchStatesList();
    fetchTreatmentTypes(facility_types);
  }, [])


  const handleInput = (ev) => {
    updateOnboardingState({ [ev.target.name]: ev.target.value })
  }

  const handlePhoneNumberChange = (formattedNumber) => {
    updateOnboardingState(formattedNumber);
  };

  function handleFileInput(ev) {
    if (ev.target.files && ev.target.files[0]) {
      updateOnboardingState({ [ev.target.name]: ev.target.files[0] });

      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        setProfileImageURL(evt.target.result)
      });
      FR.readAsDataURL(ev.target.files[0]);
    }
  }
  useEffect(() => {
    if (onboardingState?.facility_image && Object.keys(onboardingState?.facility_image).length) {
      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        setProfileImageURL(evt.target.result)
      });
      FR.readAsDataURL(onboardingState?.facility_image);
    }
  }, [onboardingState.facility_image])


  const isValid = () => {
    return onboardingState?.facility_name
      && (onboardingState.facility_type_ids?.length > 0)
      && validatePhoneNumber(onboardingState.business_number)
      && (onboardingState.website_link ? validateUrl(onboardingState.website_link) : true)
      && validateOrganizationName(onboardingState.facility_name)
      && validateText(onboardingState.city)
      && validateNumeric(onboardingState.state)
      && validateZipcode(onboardingState.zip_code)
      && validateStreetAddress(onboardingState.address1)
  }

  const handleStreetAddress = (ev) => {
    updateOnboardingState({
      address1: ev.target?.value,
    });
}

  const handleAddressChange = (Address) => {
    if (!Address) {
      updateOnboardingState({
        address1: "",
      });
    } else {
      setCountryState(Address?.state);
      updateOnboardingState({
        address1: Address?.location?.split(",")[0] || Address?.location,
        city: Address?.city,
        zip_code: Address?.zipCode,
        lat: Address?.lat,
        lng: Address?.lng,
      });
    }
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid())
      next()
    else
      setIsInvalid(true)
  }


  return (
    <Container maxWidth='md'>
      <Paper
        sx={{
          p: 6,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h5' mb={1.5}>
          Treatment Program Profile
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              label='Treatment Facility Name'
              fullWidth
              onChange={handleInput}
              value={onboardingState.facility_name || ''}
              name='facility_name'
              error={isInvalid && !validateOrganizationName(onboardingState.facility_name)}
              helperText={
                isInvalid
                && !validateOrganizationName(onboardingState.facility_name)
                && "Please add Treatment Facility Name"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="facility-type-label">Treatment Facility Type</InputLabel>
              <Select
                labelId="facility-type-label"
                label='Treatment Facility Type'
                fullWidth
                renderValue={(selected) => selected.map((val) => facilityTypesList.find((item) => item.id === +val)?.name).join(', ')}
                name='facility_type_ids'
                multiple
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                IconComponent={(props) => (
                  <Box
                    {...props}
                    component='span'
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '50% !important',
                      right: '16px !important',
                      transform: 'translateY(-50%) !important',
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                )}
                onChange={handleInput}
                value={onboardingState.facility_type_ids || []}
                error={isInvalid && (onboardingState.facility_type_ids || []).length < 1}
              >
                <MenuItem
                  sx={{
                    p: 0
                  }}
                  value={null}
                  disabled
                />
                {facilityTypesList.map((item) => (
                  <MenuItem
                    sx={{
                      py: 1,
                      gap: 1
                    }}
                    value={item.id}
                    key={item.id}
                  >
                    <Checkbox sx={{ p: 0 }} checked={Boolean((onboardingState.facility_type_ids || []).find((val) => +val === item.id))} />
                    <Typography variant='body2'>{item.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <PhoneNumberInput
              variant='outlined'
              label='Phone Number'
              placeholder="(123) 456-7890"
              fullWidth
              onChange={handlePhoneNumberChange}
              value={onboardingState.business_number || ''}
              name='business_number'
              error={
                isInvalid &&
                !validatePhoneNumber(onboardingState.business_number)
              }
              sx={{
                mb: 1.5
              }}
              helperText={
                isInvalid 
                && !validatePhoneNumber(onboardingState.business_number)
                && "Please add valid phone number"
              }
            />
            <TextField
              variant="outlined"
              label="Website Link"
              fullWidth
              onChange={handleInput}
              value={onboardingState.website_link || ''}
              name="website_link"
              error={
                isInvalid && !validateUrl(onboardingState.website_link)
              }
              helperText={
                isInvalid &&
                  !validateUrl(onboardingState.website_link)
                  ? "Please add a valid Website Link"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '1px solid',
                borderColor: 'secondary.main',
                borderRadius: 1.5,
                height: 142,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                color: 'border.dark',
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative'
              }}
              component='label'
            >
              {profileImageURL ? (
                <>
                  <Avatar
                    src={profileImageURL}
                    sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                  />
                  <Typography
                    variant='body2'
                    sx={{
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      width: '100%',
                      color: 'background.paper'
                    }}
                  >
                    Edit
                  </Typography>
                </>
              ) : (
                <>
                  <UploadIcon />
                  <Typography variant='body2' color='text.primary'>Upload Logo</Typography>
                </>
              )}
              <Box
                component='input'
                sx={{
                  display: 'none'
                }}
                type='file'
                accept='image/*'
                onChange={handleFileInput}
                name='facility_image'
              />
            </Box>
          </Grid>
        </Grid>

        <Typography variant='h5' mb={1.5}>
          Primary program address
        </Typography>

        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <PlaceAutoComplete
              variant="outlined"
              label="Street address"
              fullWidth
              onChange={handleAddressChange}
              onKeyDown={handleStreetAddress}
              defaultValue={onboardingState.address1 || ""}
              name="address1"
              error={isInvalid && !validateStreetAddress(onboardingState.address1)}
              helperText={
                isInvalid &&
                !validateStreetAddress(onboardingState.address1) &&
                "Please add valid street address"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='City'
              fullWidth
              onChange={handleInput}
              value={onboardingState.city || ''}
              name='city'
              error={isInvalid && !validateText(onboardingState.city)}
              helperText={
                isInvalid
                && !validateText(onboardingState.city)
                && "Please add valid city"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              variant='outlined'
              label='State'
              fullWidth
              SelectProps={{
                IconComponent: () => (
                  <Box
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: 2
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                ),
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 220,
                      width: 250,
                    },
                  },
                }
              }}
              onChange={handleInput}
              value={onboardingState.state || 0}
              name='state'
              error={isInvalid && !validateNumeric(onboardingState.state || 0)}
              helperText={
                isInvalid
                && !validateNumeric(onboardingState.state)
                && "Please select state"
              }
            >
              <MenuItem value={0} disabled>
                Select a state
              </MenuItem>
              {statesList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant='outlined'
              label='ZIP code'
              fullWidth
              onChange={handleInput}
              value={onboardingState.zip_code || ''}
              name='zip_code'
              error={
                isInvalid && !validateZipcode(onboardingState.zip_code)
              }
              helperText={
                isInvalid 
                && !validateZipcode(onboardingState.zip_code)
                && "Please add valid zip code"
              }
            />
          </Grid>
        </Grid>
      </Paper>

      <Box pt={5} textAlign='center'>
        <Button
          variant='contained'
          sx={{
            minWidth: 212
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default TreatmentProgramProfile;