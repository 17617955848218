import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { useHomePage } from '../../contexts/HomePage';
import { useNetwork } from '../../contexts/NetWork';
import { ERROR, SUCCESS, full_and_need_to_refer_out } from '../../constants/common';

const ConnectionCard = ({ data }) => { 
    const { connectWithOthers, connectStatus, updateNetworkState } = useNetwork();
    const { fetchSuggestedPeople } = useHomePage();
    const [loading, setLoading] = useState(false);

    function connect(connected_user_id) {
        setLoading(true)
        connectWithOthers({ connected_user_id, account_type:data.account_type });
    }

    useEffect(() => {
        if (connectStatus === SUCCESS) {
            updateNetworkState('connectStatus', '');
            fetchSuggestedPeople();
        }
        if ([SUCCESS, ERROR].includes(connectStatus))
            setLoading(false);
    }, [connectStatus])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
            }}
        >
            <Badge
                color={data?.info?.current_status === full_and_need_to_refer_out ? 'error' : 'success'}
                overlap="circular"
                badgeContent=" "
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiBadge-badge': {
                        minWidth: 12,
                        height: 12
                    }
                }}
            >
                <Avatar
                    sx={{
                        width: 36,
                        height: 36
                    }}
                    src={data?.main_image}
                />
            </Badge>

            <Box flexGrow={1}>
                <Link href='#' variant='body2' color='text.primary' textTransform='capitalize'>{data.first_name} {data.last_name}</Link>
                <Typography variant='caption' color='text.secondary' component='p' mb={0.25}>{data?.info?.headline}</Typography>
                <LoadingButton
                    size='small'
                    fullWidth
                    sx={{
                        backgroundColor: 'primary.light',
                    }}
                    onClick={function () {
                        connect(data.id)
                    }}
                    loading={loading}
                >
                    Connect
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default ConnectionCard