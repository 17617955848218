import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { ReactComponent as ShieldExclamation } from "../../../assets/Icons/shield-exclamation.svg";

import Container from "@mui/material/Container";
import { useOnboarding } from "../../../contexts/onboarding";
import TextField from "@mui/material/TextField";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";

import DegreeCard from "../../DegreeCard";
import { useDegrees } from "../../../contexts/Degrees";
import { ReactComponent as AddIcon } from "../../../assets/Icons/add.svg";
import { useCommon } from "../../../contexts/common";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { validateOrganizationName, yearValidation } from "../../../helper/Validations";

const Education = () => {
  const { next, onboardingState, setOnboardingState } = useOnboarding();
  const { fetchDegreesList, degreesList: AllDegreeList } = useCommon();
  const { handleOpen } = useDegrees();
  const [showAdd, setShowAdd] = useState(false);

  const initialState = {
    professional_degree_id: null,
    degree_year: "",
    college_name: "",
    practice_year: "",
  };

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isDegreeAlreadyExist, setIsDegreeAlreadyExist] = useState(false);

  const isValid = () => {
    return (
      +state.professional_degree_id 
      && !isDegreeAlreadyExist 
      && (state.practice_year && state.degree_year) ? state.practice_year >= state.degree_year : true 
      && yearValidation(state.degree_year) 
      && yearValidation(state.practice_year) 
      && validateOrganizationName(state.college_name)
    );
  }

  const handleSubmit = () => {
    if (isValid()) {
      next();
    } else {
      setIsInvalid(true);
      return;
    }
  }

  const addLater = () => {
    setOnboardingState((prevState) => {
      delete onboardingState.degrees;
      delete prevState.degrees;
      localStorage.setItem("onboardingData", JSON.stringify(prevState));
      return prevState;
    });
    next();
  }

  const addNew = () => {
    if (state.professional_degree_id !== null) {
      if (isValid()) {
        setOnboardingState((prevState) => ({
          ...prevState,
          degrees: [...(prevState.degrees || []), { ...state }],
        }));
        setIsInvalid(false);
        setState(initialState);
      } else {
        setIsInvalid(true);
      }
    }
  }

  const editDegree = (data) => {
    setState(initialState);
    handleOpen(data, true);
  }

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  useEffect(() => {
    fetchDegreesList();
  }, []);

  useEffect(() => {
    if (onboardingState.degrees?.length > 0) {
      setShowAdd(true);
    } else {
      setShowAdd(false);
    }
  }, [onboardingState.degrees?.length]);

  const handleChangeDegree = (selected) => {
    const pervDegree = onboardingState?.degrees?.filter(
      (degree) => degree.professional_degree_id == selected.target.value
    )[0];
    if (pervDegree) {
      setIsDegreeAlreadyExist(true);
    } else {
      setIsDegreeAlreadyExist(false);
    }
    setState((prevState) => ({
      ...prevState,
      professional_degree_id: selected.target.value,
    }));
  };

  return (
    <Container maxWidth="md">
      <Box>
        <div className="education">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4" mb={1} fontWeight={600}>
                Education
              </Typography>
              <Typography variant="body1" mb={3}>
                Where did you go to school, and when did you begin your
                practice?
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                label="Professional Degree"
                fullWidth
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  },
                }}
                onChange={(selected) => handleChangeDegree(selected)}
                value={state.professional_degree_id || 0}
                name="professional_degree_id"
                error={
                  (isInvalid && !+state.professional_degree_id) ||
                  isDegreeAlreadyExist
                }
                helperText={
                  (isInvalid && !+state.professional_degree_id) ||
                    isDegreeAlreadyExist
                    ? "please choose another degree"
                    : ""
                }
              >
                <MenuItem value={0} disabled>
                  Select a degree
                </MenuItem>
                {AllDegreeList?.length &&
                  AllDegreeList?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
              {/* {isDegreeAlreadyExist && (
                <Alert
                  severity="error"
                  sx={{
                    backgroundColor: "#FAD4D4",
                    borderRadius: 1,
                    border: "1px solid #EE6363",

                    py: 0.5,
                    px: 3,
                    alignItems: "center",
                    color: "text.primary",
                  }}
                  icon={<ShieldExclamation />}
                >
                  Please add another degree.
                </Alert>
              )} */}
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                label="Year graduated (YYYY)"
                fullWidth
                onChange={handleInput}
                value={state.degree_year || ""}
                name="degree_year"
                error={
                  isInvalid &&
                  (!yearValidation(state.degree_year) ||
                    state.practice_year < state.degree_year)
                }
                helperText={
                  isInvalid &&
                  (!yearValidation(state.degree_year) ||
                    state.practice_year < state.degree_year
                    ? "please add a valid year"
                    : "")
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                label="Practice Year (YYYY)"
                fullWidth
                onChange={handleInput}
                value={state.practice_year || ""}
                name="practice_year"
                error={
                  isInvalid &&
                  (!yearValidation(state.practice_year) ||
                    state.practice_year < state.degree_year)
                }
                helperText={
                  isInvalid &&
                  (!yearValidation(state.practice_year) ||
                    state.practice_year < state.degree_year
                    ? "please add a valid year"
                    : "")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="School Name"
                fullWidth
                onChange={handleInput}
                value={state.college_name || ""}
                name="college_name"
                error={isInvalid && !validateOrganizationName(state.college_name)}
                helperText={
                  isInvalid
                  && !validateOrganizationName(state.college_name)
                    ? "Please add valid School name"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Button
            sx={{
              color: "primary.main",
              p: 1.125,
              minWidth: 0,
              mt: 1.5,
              mb: 0.5,
            }}
            onClick={addNew}
            variant="outlined"
          >
            <AddIcon width={18} height={18} />
            {onboardingState?.degrees?.length != 0
              ? "Add New Education"
              : "Add Education"}{" "}
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {onboardingState.degrees?.map((item, index) => (
              <DegreeCard
                key={item.id}
                data={item}
                onEdit={editDegree}
                index={index}
                isLocalEditing
                isOnBoarding={true}
              />
            ))}
          </Box>
        </div>

        <div className="buttons_education">
          <Button
            variant="contained"
            sx={{
              minWidth: 212,
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </Box>
    </Container>
  );
};

export default Education;
