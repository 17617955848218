import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import { ReactComponent as RadioButtonIcon } from "../../../assets/Icons/radio-button.svg";
import { ReactComponent as RadioButtonMarkedIcon } from "../../../assets/Icons/radio-button-marked.svg";
import { useOnboarding } from "../../../contexts/onboarding";
import {
  accepting_new_clients_and_taking_referrals,
  full_and_need_to_refer_out,
  somewhere_in_between,
} from "../../../constants/common";
import { ReactComponent as ShieldExclamation } from "../../../assets/Icons/shield-exclamation.svg";
import "./style.css";

const CurrentPractice = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const { next, updateOnboardingState, onboardingState } = useOnboarding();

  function handlePracticeTypeOne() {
    updateOnboardingState({
      current_status: accepting_new_clients_and_taking_referrals,
    });
  }

  function handlePracticeTypetwo() {
    updateOnboardingState({ current_status: full_and_need_to_refer_out });
  }

  function handlePracticeTypeThree() {
    updateOnboardingState({ current_status: somewhere_in_between });
  }

  function isValid() {
    return onboardingState.current_status;
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) next();
    else setIsInvalid(true);
  }

  return (
    <Container maxWidth="md">
      <div className="practiceForm">
        <Typography variant="h5" mb={3}>
          Which of the following best describes your current practice?
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          startIcon={
            onboardingState.current_status ===
              accepting_new_clients_and_taking_referrals ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handlePracticeTypeOne}
        >
          Accepting new clients
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          startIcon={
            onboardingState.current_status === full_and_need_to_refer_out ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handlePracticeTypetwo}
        >
          Not accepting clients
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          sx={{
            color: "text.primary",
            mb: 3,
            gap: "6px",
            justifyContent: "flex-start",
            minHeight: 65,
            fontSize: 16,
            fontWeight: 400,
          }}
          startIcon={
            onboardingState.current_status === somewhere_in_between ? (
              <RadioButtonMarkedIcon />
            ) : (
              <RadioButtonIcon />
            )
          }
          onClick={handlePracticeTypeThree}
        >
          Somewhere in between
        </Button>

        {isInvalid && !onboardingState.current_status && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#FAD4D4",
              borderRadius: 1,
              border: "1px solid #EE6363",
              mb: 1.5,
              mt: -1.5,
              py: 0.5,
              px: 3,
              alignItems: "center",
              color: "text.primary",
            }}
            icon={<ShieldExclamation />}
          >
            You have to select one of the previous options
          </Alert>
        )}
      </div>

      <Box pt={5} textAlign="center">
        <Button
          className="btnContinue"
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit}
          disabled={!onboardingState.current_status}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default CurrentPractice;
