import React, { useEffect, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import { ReactComponent as VerifyIcon } from '../../assets/Icons/verify-outlined.svg';
import { ReactComponent as HeartIcon } from '../../assets/Icons/heart.svg';
import { ReactComponent as HeartFilledIcon } from '../../assets/Icons/heart-filled.svg';
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg';
import paths from '../../constants/paths';
import { ReactComponent as ShareTransmitIcon } from '../../assets/Icons/share-transmit.svg'
import { ReactComponent as AddUserIcon } from '../../assets/Icons/user-add-plus.svg'
import { ReactComponent as SendIcon } from '../../assets/Icons/send-filled.svg'
import { ReactComponent as ArrowIcon } from '../../assets/Icons/arrow-left.svg'
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useUserProfile } from '../../contexts/UserProfile';
import { useNetwork } from '../../contexts/NetWork';
import { LOADING, treatment_program } from '../../constants/common';
import ReferalModal from '../../components/ReferalModal';
import SendToFriendModal from '../../components/SendToFriendModal';
import { useGeneralProfile } from '../../contexts/Organization';
import { useAuth } from '../../contexts/Auth';
import { useSearchParams } from 'react-router-dom';

const ProfileHeader = ({ showConnections, setShowConnections }) => {
	const { data: userInfo } = useBasicInfo()
	const { facilityInfo } = useGeneralProfile()
	const { data, likeUser } = useUserProfile();
	const { account_type } = useAuth();
	const [searchParams] = useSearchParams();
	const profileAccountType = searchParams.get('account_type');
	const { connectWithOthers, connectStatus } = useNetwork();

	const [scrollPosition, setScrollPosition] = useState(0);
	const [openReferral, setOpenReferral] = useState(false);
	const [openShare, setOpenShare] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		handleCloseConnections();
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	
	
	const info = account_type === treatment_program ? facilityInfo : userInfo;
	const handleOpenConnections = () => { setShowConnections(true) }
	const handleCloseConnections = () => { setShowConnections(false) }
	const handleOpenReferralModal = () => { setOpenReferral(true) }
	const handleCloseReferralModal = () => { setOpenReferral(false) }
	const handleOpenShareModal = () => { setOpenShare(true) }
	const handleCloseShareModal = () => { setOpenShare(false) }

	const connect = () => {
		connectWithOthers(
			{ 
				connected_user_id: data.id, 
				account_type: account_type === treatment_program ? treatment_program : 'user'
			}
		);
	}

	const favouriteUser = () => {
		likeUser(
			{ 
				favorite_user_id: data.id, 
				account_type: account_type === treatment_program ? treatment_program : 'user' 
			}
		);
	}

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};
	
	const isScrolled = scrollPosition > 0 || showConnections;

	return (
		<>
			<Paper
				sx={{
					mb: 3,
					position: 'sticky',
					top: 56,
					zIndex: 9,
					boxShadow:  isScrolled ? '0px 0px 32px 0px rgba(0, 0, 0, 0.08)' : '0px 0px 64px 0px rgba(0, 0, 0, 0.04)',
				}}
			>
				<Container>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center', 
							flexGrow: 1,
							gap: isScrolled ? 2 : 3,
							py: isScrolled ? 1 : 3,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',  
								gap: 2,
							}}
						>
							{showConnections && (
								<IconButton
									sx={{
										p: 0,
										color: 'primary.main'
									}}
									onClick={handleCloseConnections}
								>
									<ArrowIcon />
								</IconButton>
							)}
							<Avatar
								sx={{
									width: isScrolled ? 36 : 110,
									height: isScrolled ? 36 : 110,
								}}
								src={data?.main_image}
							/>
						</Box>
						<Box
							sx={{flexGrow: 1}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									gap: 0.5,
								}}
							>
								<Typography 
									variant={isScrolled ? 'body1' : 'h4'} 
									textTransform='capitalize'
								>
									{
										profileAccountType === treatment_program ? 
											data?.info?.facility_name : 
											`${data?.first_name} ${data?.last_name}`
									}
								</Typography>
								{info.id === data.id && (
									<Link
										href={account_type === treatment_program ? paths.profileOrganization : paths.profileBasic}
										sx={{display: 'flex'}}
									>
										<EditIcon />
									</Link>
								)}
							</Box>
							{!isScrolled && (
								<>
									{data?.info?.headline && <Typography variant='body1' color='text.secondary'>{data?.info?.headline}</Typography>}
									{info.id !== data.id  && (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 0.5,
												color: 'success.main'
											}}
										>
											<VerifyIcon />
											<Typography variant='body2'>Verified Account</Typography>
										</Box>
									)}
									{Boolean(data?.connections?.length) && (
										<Button
											variant='body2'
											onClick={handleOpenConnections}
											sx={{
												p: 0,
												color: 'primary.main',
												'&:hover': {
													backgroundColor: 'transparent'
												}
											}}
											disableRipple
										>
											{data?.connections?.length} Connections
										</Button>
									)}
								</>
							)}
						</Box>
						{info.id !== data.id && (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 1.25
								}}
							>
								{Boolean(data.is_connected) && (
									<IconButton
										onClick={favouriteUser}
										sx={{
											color: data.is_favorite ? 'error.main' : 'border.dark'
										}}
									>
										{Boolean(data.is_favorite) ? (
											<HeartFilledIcon />
										) : (
											<HeartIcon />
										)}
									</IconButton>
								)}
								<Button
									variant='outlined'
									onClick={handleOpenShareModal}
									startIcon={<ShareTransmitIcon />}
									sx={{
										py: 0.75,
										whiteSpace: 'nowrap'
									}}
								>
									Share
								</Button>
								{!data.is_connected && info.id !== data.id && (
									<LoadingButton
										variant='contained'
										onClick={connect}
										loading={connectStatus === LOADING}
										startIcon={<AddUserIcon />}
										sx={{
											py: 0.75,
											whiteSpace: 'nowrap',
											color: 'background.paper'
										}}
									>
										Connect
									</LoadingButton>
								)}
								<Button
									variant='contained'
									onClick={handleOpenReferralModal}
									startIcon={<SendIcon />}
									sx={{
										py: 0.75,
										whiteSpace: 'nowrap'
									}}
								>
									Send Referral
								</Button>
							</Box>
						)}
					</Box>
				</Container>
			</Paper>
			<ReferalModal
				open={openReferral}
				onClose={handleCloseReferralModal}
				data={data}
				isProfile={true}
			/>
			<SendToFriendModal
				open={openShare}
				onClose={handleCloseShareModal}
				data={data}
			/>
		</>
	)
}

export default ProfileHeader