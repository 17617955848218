import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { favouriteUser, getUserProfile, sendToFriend, unfavouriteUser } from '../services/userProfile.services';
import { useAlert } from './Alert';


const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
    const { sendAlert } = useAlert();

    const [userProfileState, setUserProfileState] = useState({
        data: {},
        fetchStatus: '',
        likeStatus: '',
        sendToFriendStatus: ''
    });

    function handleState(obj) {
        setUserProfileState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }
 
    async function fetchUserProfile(id) {
        handleState({ fetchStatus: LOADING })
        try {
            const response = await getUserProfile(id);
            handleState({
                data: response.data.data,
                fetchStatus: SUCCESS
            })
        } catch (error) {
            handleState({ fetchStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }


    async function likeUser(data, isLiked, isNetwork) {
        handleState({
            likeStatus: LOADING,
            data: {
                ...userProfileState.data,
                is_favorite: !userProfileState.data.is_favorite
            }
        })
        try {
            let response
            if (isNetwork ? isLiked : userProfileState.data.is_favorite ) {
                response = await unfavouriteUser(data);
            } else {
                response = await favouriteUser(data);
            }
            
            handleState({
                likeStatus: SUCCESS,
            })
            return response;
        } catch (error) {
            handleState({
                likeStatus: ERROR,
                data: {
                    ...userProfileState.data,
                    is_favorite: !userProfileState.data.is_favorite
                }
            })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    async function sendEmailToFriend(data) {
        handleState({ sendToFriendStatus: LOADING })
        try {
            await sendToFriend(data);
            handleState({
                sendToFriendStatus: SUCCESS
            })
            sendAlert('Therapist information has been shared successfully', 'success')
        } catch (error) {
            handleState({ sendToFriendStatus: ERROR })
            sendAlert(error.response?.data?.message, 'error')
            Promise.reject(error)
        }
    }

    function updateUserProfileState(field, value) {
        handleState({ [field]: value })
    }

    const state = {
        ...userProfileState,
        fetchUserProfile,
        likeUser,
        sendEmailToFriend,
        updateUserProfileState
    };

    return (
        <UserProfileContext.Provider value={state}>
            {children}
        </UserProfileContext.Provider>
    );
};

export const useUserProfile = () => useContext(UserProfileContext);