import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  Divider,
  Grid,
  Skeleton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as ThermometerIcon } from '../../assets/Icons/thermometer.svg';
import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg';
import { LOADING, SUCCESS, inperson_session, psypact_provider, has_video_provided, medication_prescriber, treatment_program } from '../../constants/common';
import AgesSelection from '../../components/AgesSelection';
import CheckBox from '../../components/CheckBox';
import { useClientServed } from '../../contexts/ClientServed';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';
import sortAgeRanges from "../../utils/sortAges"

const ProfileClientsServed = () => {
  const { account_type } = useAuth();
  const [state, setState] = useState({
    age_served: [],
    notify_favorite_colleagues: 0,
    inperson_session: 0,
    has_video_provided: 0,
    psypact_provider: 0,
    currently_served: []
  });
  const { fetchInfo, data } = useBasicInfo();
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const info = account_type !== treatment_program ? data : facilityInfo;
  const {
    fetchUserClientTypes,
    userClientTypes,
    fetchUserClientTypesStatus,
    handleOpenClientsServedDrawer,
    editAgesServed,
    editAgesServedStatus,
    updateClientTypesState
  } = useClientServed();

  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj
    }));
  };

  const handleSaveAgesServed = () => {
    const payload = {
      ...state,
      ages: state.age_served.length > 0 ? sortAgeRanges(state.age_served).join(',') : [],
    };
    if (account_type === treatment_program) delete payload.age_served;
    editAgesServed(payload, account_type !== treatment_program ? 'info' : 'program-info');
  };

  const handleCheckBox = (ev) => {
    updateState({ [ev.target.name]: ev.target.checked ? 1 : 0 });
  };

  useEffect(() => {
    if (account_type !== treatment_program) fetchUserClientTypes();
  }, [account_type]);

  useEffect(() => {
    if (editAgesServedStatus === SUCCESS) {
      updateClientTypesState('editAgesServedStatus', '');
      account_type !== treatment_program ? fetchInfo() : fetchFacilityInfo();
    }
  }, [editAgesServedStatus, account_type, fetchInfo, fetchFacilityInfo, updateClientTypesState]);

  useEffect(() => {
    if (info) {
      updateState({
        age_served: info?.info?.age_served?.split(',') || [],
        notify_favorite_colleagues: info?.info?.notify_favorite_colleagues || 0,
        inperson_session: info?.info?.inperson_session || 0,
        has_video_provided: info?.info?.has_video_provided || 0,
        psypact_provider: info?.info?.psypact_provider || 0,
        currently_served: info?.client_types?.filter(client => client?.currently_served) || []
      });
    }
  }, [info]);

  const renderClientTypes = (title, data) => (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>{title}</Typography>
      </Grid>
      {fetchUserClientTypesStatus === LOADING
        ? Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton animation="wave" />
            </Grid>
          ))
        : data.map((item) => (
            <Grid item xs={6} key={item.id}>
              {item.name}
            </Grid>
          ))}
    </>
  );

  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
      {account_type !== treatment_program && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <ThermometerIcon width={24} height={24} />
              <Typography variant='h4' ml={1}>Clients Served</Typography>
            </Box>
            <Typography variant='body1'>Select the types of clients you work with.</Typography>
          </Box>
          {userClientTypes.length > 0 ? (
            <IconButton sx={{ p: 0.75 }} onClick={handleOpenClientsServedDrawer}>
              <EditIcon />
            </IconButton>
          ) : (
            <Button variant='contained' size='large' sx={{ minWidth: 150 }} onClick={handleOpenClientsServedDrawer}>
              Add New
            </Button>
          )}
        </Box>
      )}

      {userClientTypes.length > 0 && account_type !== treatment_program && <Divider />}

      {account_type !== treatment_program && (
        <Grid container spacing={2.5}>
          {renderClientTypes('Client types', userClientTypes)}
        </Grid>
      )}

      {userClientTypes.length > 0 && state.currently_served.length > 0 && account_type !== treatment_program && <Divider />}

      {state.currently_served.length > 0 && account_type !== treatment_program && (
        <Grid container spacing={2.5}>
          {renderClientTypes('Currently Served', state.currently_served)}
        </Grid>
      )}

      {account_type !== treatment_program && <Divider />}

      <AgesSelection data={state} setData={updateState} />

      {account_type !== treatment_program && (
        <>
          <Divider />
          <CheckBox
            label={has_video_provided.label}
            name={has_video_provided.value}
            onChange={handleCheckBox}
            checked={Boolean(state.has_video_provided)}
          />
          <CheckBox
            label={inperson_session.label}
            name={inperson_session.value}
            onChange={handleCheckBox}
            checked={Boolean(state.inperson_session)}
          />
          <CheckBox
            label={psypact_provider.label}
            name={psypact_provider.value}
            onChange={handleCheckBox}
            checked={Boolean(state.psypact_provider)}
          />
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          variant='contained'
          sx={{ minWidth: 150 }}
          onClick={handleSaveAgesServed}
          loading={editAgesServedStatus === LOADING}
        >
          Save
        </LoadingButton>
      </Box>
    </Paper>
  );
};

export default ProfileClientsServed;
