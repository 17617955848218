import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const formatPhoneNumber = (value) => {
  let formatted = '';
  if (value?.length > 0) {
    if (value.length === 11 && value.startsWith('1')) {
      formatted = `+1 (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
    } else if (value.length <= 10) {
      formatted = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  }

  return formatted;
};

const PhoneNumberInput = ({ name, value, onChange, error, label, helperText, ...rest }) => {
  const [state, setState] = useState({ number: value });
  const [displayValue, setDisplayValue] = useState(formatPhoneNumber(value));
  const [isInvalid, setIsInvalid] = useState(false);

  let obj = new Object();
  obj[name] = '';

  useEffect(() => {
    setState({ number: value });
    setDisplayValue(formatPhoneNumber(value));
  }, [value]);

  const handleInput = (e) => {
    e.preventDefault();
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, '');

    if (input.length < displayValue.length) {
      const newCleaned = cleaned.slice(0, cleaned.length - 1);
      setState({ number: newCleaned });
      setDisplayValue(formatPhoneNumber(newCleaned));
      obj[name] = newCleaned;
      onChange(obj);
      return;
    }

    if (cleaned.length > 11 || (cleaned.length > 10 && !cleaned.startsWith('1'))) return;

    setState({ ...state, number: cleaned });
    setDisplayValue(formatPhoneNumber(cleaned));
    obj[name] = cleaned;
    onChange(obj);

    if (!validatePhoneNumber(cleaned)) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  };

  const validatePhoneNumber = (number) => {
    if (!number) return true;
    const regex = /^\d{10,11}$/;
    return regex.test(number);
  };

  return (
    <TextField
      {...rest}
      label={label}
      onChange={handleInput}
      name={name}
      error={error || (isInvalid && !validatePhoneNumber(state.number))}
      value={displayValue}
      helperText={helperText || (
        isInvalid &&
        !validatePhoneNumber(state.number) &&
        'Please enter a valid fax number'
      )}
    />
  );
};

export default PhoneNumberInput;
