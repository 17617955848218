import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InvitationModal from "../../components/InvitationModal";
import ConfirmationModal from "../../components/ConfirmationModal";

import { useTeams } from "../../contexts/Teams";
import { LOADING, SUCCESS } from "../../constants/common";
import MemberRow from "./MemberRow";
import { Skeleton } from "@mui/material";
// import EditRoleModal from "../../components/EditRoleModal";

const ProfileTeam = () => {
  const {
    fetchTeams,
    teams,
    fetchTeamsStatus,
    removeMember,
    removeMemberStatus,
    revokeInvitation,
    revokeInvitationStatus,
    resendInvitation,
    resendInvitationStatus,
    updateTeamsState,
  } = useTeams();
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  // const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [activeMember, setActiveMember] = useState({});

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    if (removeMemberStatus === SUCCESS) {
      updateTeamsState("removeMemberStatus", "");
      handleCloseConfirmationModal();
      fetchTeams();
    }
  }, [removeMemberStatus]);

  useEffect(() => {
    if (revokeInvitationStatus === SUCCESS) {
      updateTeamsState("revokeInvitationStatus", "");
      handleCloseRevokeModal();
      fetchTeams();
    }

    if (resendInvitationStatus === SUCCESS) {
      updateTeamsState("resendInvitationStatus", "");
      fetchTeams();
    }
  }, [revokeInvitationStatus, resendInvitationStatus]);

  const handleOpenInvitationModal = () => {
    setOpenInvitationModal(true);
  }

  const handleCloseInvitationModal = () => {
    setOpenInvitationModal(false);
  }

  const handleOpenConfirmationModal = (data) => {
    setActiveMember(data);
    setOpenConfirmationModal(true);
  }

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setActiveMember("");
  }

  const handleOpenRevokeModal = (data) => {
    setActiveMember(data);
    setOpenRevokeModal(true);
  }

  const handleCloseRevokeModal = () => {
    setOpenRevokeModal(false);
    setActiveMember("");
  }

  const removeMemberFunction = () => {
    removeMember({ id: activeMember?.id });
  }

  const resendInvitationFunction = (data) => {
    resendInvitation({ user_id: data.id, ...data?.params });
  }

  const revokeInvitationFunction = () => {
    revokeInvitation({
      user_id: activeMember?.id,
      team_id: activeMember?.params?.team_id,
      email: activeMember?.params?.email,
    });
  }

  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Team Invitations</Typography>
          <Button variant="contained" onClick={handleOpenInvitationModal}>
            Invite Members
          </Button>
        </Box>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "#EEEFF0",
            p: 3,
            borderRadius: 1.5,
          }}
        >
          <Typography variant="h5" mb={3}>
            Accepted
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  sx={{ pl: 0, borderColor: "#a3a6a8", minWidth: "280px" }}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Account
                  </Typography>
                </TableCell>
                <TableCell component="th" sx={{ borderColor: "#a3a6a8", minWidth: "260px" }}>
                  <Typography variant="body2" fontWeight={600}>
                    Date
                  </Typography>
                </TableCell>
                <TableCell component="th" sx={{ borderColor: "#a3a6a8", minWidth: "330px" }}>
                  <Typography variant="body2" fontWeight={600}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchTeamsStatus === LOADING ? (
                <TableCell colSpan={3} sx={{ px: 0 }}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                </TableCell>
              ) : (
                teams?.accepted?.map((item, index) => (
                  <MemberRow
                    key={item.id}
                    data={{ ...item, params: (item?.params || [])[1] || {} }}
                    index={index}
                    length={teams?.accepted.length - 1}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    isAccepted
                  />
                ))
              )}
            </TableBody>
          </Table>
        </Box>

        <Box
          sx={{
            border: "1px solid",
            borderColor: "#EEEFF0",
            p: 3,
            borderRadius: 1.5,
          }}
        >
          <Typography variant="h5" mb={3}>
            Pending
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  sx={{ pl: 0, borderColor: "#a3a6a8", minWidth: "280px" }}
                >
                  <Typography variant="body2" fontWeight={600}>
                    Account
                  </Typography>
                </TableCell>
                <TableCell component="th" sx={{ borderColor: "#a3a6a8", minWidth: "130px" }}>
                  <Typography variant="body2" fontWeight={600}>
                    Sent
                  </Typography>
                </TableCell>
                <TableCell component="th" sx={{ borderColor: "#a3a6a8", minWidth: "130px" }}>
                  <Typography variant="body2" fontWeight={600}>
                    Revoked
                  </Typography>
                </TableCell>
                <TableCell component="th" sx={{ borderColor: "#a3a6a8", minWidth: "330px" }}>
                  <Typography variant="body2" fontWeight={600}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchTeamsStatus === LOADING ? (
                <TableCell colSpan={3} sx={{ px: 0 }}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: 40,
                    }}
                  />
                </TableCell>
              ) : (
                teams?.pending?.map((item, index) => (
                  <MemberRow
                    key={item.id}
                    data={item}
                    index={index}
                    length={teams?.pending.length - 1}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    resendInvitationFunction={resendInvitationFunction}
                    handleOpenRevokeModal={handleOpenRevokeModal}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </Paper>
      {openInvitationModal && (
        <InvitationModal
          onClose={handleCloseInvitationModal}
          open={openInvitationModal}
        />
      )}{" "}
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        message={`Are you sure you want to delete ${
          activeMember?.name || activeMember?.email
        }?`}
        deleteFunction={removeMemberFunction}
        loading={removeMemberStatus === LOADING}
      />
      <ConfirmationModal
        open={openRevokeModal}
        onClose={handleCloseRevokeModal}
        message={`Are you sure you want to revoke ${
          activeMember?.name || activeMember?.email
        } invitation?`}
        deleteFunction={revokeInvitationFunction}
        loading={revokeInvitationStatus === LOADING}
        modalName="Revoke"
      />
    </>
  );
};

export default ProfileTeam;
